import {motion, useAnimation, useInView} from "framer-motion";
import React, {useEffect, useRef, useState} from "react";

interface Props {
    children: JSX.Element
}

const Reveal = ({children}: Props) => {
    const [scrollDirection, setScrollDirection] = useState('')
    const [prevScrollY, setPrevScrollY] = useState(0)
    const ref = useRef(null)
    const isInView = useInView(ref)
    const control = useAnimation()

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY
            if (currentScrollY > prevScrollY) {
                setScrollDirection('down')
            } else if (currentScrollY < prevScrollY) {
                setScrollDirection('up')
            }
            setPrevScrollY(currentScrollY)
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [prevScrollY])

    useEffect(() => {
        if (isInView) {
            control.start('visible')
        } else if (!isInView && scrollDirection === 'up') {
            control.start('hidden')
        }
    },[control, isInView])

    return (
            <motion.div
                ref={ref}
                style={{overflow:'hidden', height:'auto', width:'100%'}}
                variants={{
                    hidden: { opacity: 0, transition:{duration:0} },
                    visible: { opacity: 1, transition:{duration: .2, delay: .2, ease:'linear'} }
                }}
                initial={'hidden'}
                animate={control}>
                {children}
            </motion.div>
    )
}
export default Reveal