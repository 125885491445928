import React from "react";



const HamburgerIcon = (color: string) => {
    return (
            <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4 10C4 8.89543 4.89543 8 6 8H33C34.1046 8 35 8.89543 35 10C35 11.1046 34.1046 12 33 12H6C4.89543 12 4 11.1046 4 10ZM4 20C4 18.8954 4.89543 18 6 18H33C34.1046 18 35 18.8954 35 20C35 21.1046 34.1046 22 33 22H6C4.89543 22 4 21.1046 4 20ZM6 28C4.89543 28 4 28.8954 4 30C4 31.1046 4.89543 32 6 32H33C34.1046 32 35 31.1046 35 30C35 28.8954 34.1046 28 33 28H6Z" fill={color}/>
            </svg>
        )
}

export default HamburgerIcon