import React from "react";


export const redirectToExternalSite = (site: string) => {
    switch(site) {
        case "F":
            window.open( "https://www.facebook.com/75thRangerRegiment1942/")
            // window.location.href = "https://www.facebook.com/75thRangerRegiment1942/"
            break
        case "I":
            window.open("https://www.instagram.com/the_75th_ranger_regiment/igsh=MW9kNHNybTVtOXIz/")
            // window.location.href = "https://www.instagram.com/the_75th_ranger_regiment/"
            break
        case "Y":
            window.open("https://www.youtube.com/@The_75th_Ranger_Regiment")
            // window.location.href = "https://www.youtube.com/@the75thrangerregiment14"
            break
        case "FOIA":
            window.open("https://www.rmda.army.mil/foia/RMDA-FOIA-Division.html")
            // window.location.href = "https://www.rmda.army.mil/foia/RMDA-FOIA-Division.html"
            break
        case "PP":
            window.open( "https://www.army.mil/privacy/")
            // window.location.href = "https://www.army.mil/privacy/"
            break
        case "SOF":
            window.open("https://www.goarmysof.army.mil/")
            // window.location.href = "https://www.goarmysof.army.mil/"
            break
        case "Talent":
            window.open('https://talent.army.mil/tbca/')
            break
        case "Pdf":
            window.open('../documents/raspQuestionnaire.pdf')
            break
        case "RSVP":
            window.open("https://docs.google.com/forms/d/1Smxh2LW2zf_JGe-JEPGWb4yT3hOn_wZeMilSSs-E9iE/viewform?edit_requested=true")
    }
}