import React, {useState} from "react"
import BaseAccess from "./Pictures/baseAccess.webp"
import "./AllTheCSS/Access.css"
import Reveal from "./Reveal";
import AccessModal from "./AccessModal";

// interface props {
//     setPageSelector: React.Dispatch<React.SetStateAction<number>>
// }
const Access = () => {
const [showModal, setShowModal] = useState(false)
const[informationType, setInformationType] = useState('')
    const handleClick = (information:string) => {
        setShowModal(true)
        setInformationType(information)
        document.body.style.overflowY = 'hidden'
    }

    const handleCloseModal = () => {
    setShowModal(false)
    document.body.style.overflowY = 'auto'
    }

    return(
    <>
        {showModal &&
            <>
                <AccessModal informationType={informationType} handleCloseModal={handleCloseModal}/>
                <div className={"theVoid"} onClick={()=> handleCloseModal()}/>
            </>}

        <div className={'Access-Container'}>
            <Reveal>
                <div className={'Access-Content'}>
                    <div className={'Access-Info'}>
                        <div className={'Access-Info-Text'}>
                            <div className={'Access-Info-Text1'}>
                                <h2>Access for Vehicles with a Valid DOD ID</h2>
                                <ul className={'Access-List-Items-Group'}>
                                    <li className={'Access-List-Items'}>Vehicles with an adult (18 or older) holding an authorized Department of Defense (DOD) ID card can enter JBLM through</li>
                                    <li className={'Access-List-Items'}>Passengers in these vehicles do not need a separate pass.
                                        <li className={'Access-List-Items'}>
                                                Main gate’s hours with a Valid ID is 24/7
                                        </li>
                                    </li>
                                </ul>

                            </div>
                            <br></br>
                            <div className={'Access-Info-Text2'}>
                                <h2 onClick={() => handleClick('No ID')}> Access for Vehicles without a Valid DOD ID </h2>
                            </div>
                            <div className={'Access-Info-Text2'}>
                                <h2 onClick={() => handleClick('Acceptable Documents')}>Acceptable Documents</h2>
                            </div>
                            <div className={'Access-Info-Text2'}>
                                <h2 onClick={() => handleClick('Prohibited Items')}>Prohibited Items</h2>
                            </div>

                        </div>
                        <img className={'Access-Pic'} src={BaseAccess} alt={"BASE MAP"}/>
                    </div>
                    <a className={'Access-Info-Link'} href={"https://home.army.mil/lewis-mcchord/about/Directorates-support-offices/directorate-emergency-services/provost-marshal/gate-information"} style={{color: "#0288D1"}}>
                        JBLM Visitors Policy
                    </a>
                </div>
            </Reveal>

        </div>
        </>
    )
}

export default Access