import './AllTheCSS/Lodging.css';
import HotelOne from './Pictures/Hotel1.webp'
import HotelTwo from './Pictures/Hotel2.webp'
import HotelThree from './Pictures/Hotel3.webp'
import HotelFour from './Pictures/Hotel4.webp'
import HotelFive from './Pictures/Hotel5.webp'
import HotelSix from './Pictures/Hotel6.webp'
import LodgingMap from './Pictures/LodgingMap.webp'
import Reveal from "./Reveal";

const Lodging = () => {

    const handleClick = (num:number) => {
        switch (num) {
            case 1:
               return window.location.href = 'https://www.google.com/maps/place/TownePlace+Suites+Tacoma+Lakewood/@47.1503037,-122.5040016,17z/data=!3m1!4b1!4m9!3m8!1s0x5491013e6165d0db:0xc20e54b6f0dbaffb!5m2!4m1!1i2!8m2!3d47.1503001!4d-122.5014267!16s%2Fg%2F11fxb98x_c?entry=ttu';
            case 2:
                return window.location.href = 'https://www.google.com/maps/place/Fairfield+Inn+%26+Suites+Tacoma+DuPont/@47.0901266,-122.6476549,17z/data=!3m1!4b1!4m10!3m9!1s0x549108b80f11852f:0x2f38355edacb4c95!5m3!1s2024-09-21!4m1!1i2!8m2!3d47.090123!4d-122.64508!16s%2Fg%2F11c1vkpj5t?entry=ttu';
            case 3:
                return window.location.href = 'https://www.google.com/maps/place/Courtyard+Tacoma+Downtown/@47.2486459,-122.4403417,17z/data=!3m2!4b1!5s0x54905575c9dffe05:0x68387a0e94142c16!4m10!3m9!1s0x54905575b6225fd3:0x2fb6bdb92429cc83!5m3!1s2024-09-21!4m1!1i2!8m2!3d47.2486423!4d-122.4377668!16s%2Fg%2F1tgqj3jp?entry=ttu';
            case 4:
                return window.location.href = 'https://www.google.com/maps/place/La+Quinta+Inn+by+Wyndham+Olympia+-+Lacey/@47.0493296,-122.8237559,17z/data=!3m1!4b1!4m10!3m9!1s0x54910b3de5eb308d:0xbf89fd159a5a6947!5m3!1s2024-09-21!4m1!1i2!8m2!3d47.049326!4d-122.821181!16s%2Fg%2F1vd94t59?entry=ttu';
            case 5:
                return window.location.href = 'https://www.google.com/maps/place/Hampton+Inn+%26+Suites+Olympia+Lacey/@47.047885,-122.8315428,17z/data=!3m1!4b1!4m10!3m9!1s0x54910b3beb0793b7:0x58fc91ec2e9968e2!5m3!1s2024-09-21!4m1!1i2!8m2!3d47.0478814!4d-122.8289679!16s%2Fg%2F11c1nc6v56?entry=ttu';
            case 6:
                return window.location.href = 'https://www.google.com/maps/place/Holiday+Inn+Express+%26+Suites+Lacey+-+Olympia,+an+IHG+Hotel/@47.0457583,-122.828963,17z/data=!3m1!4b1!4m10!3m9!1s0x54910b393f39e67b:0x9805661b1b3694dc!5m3!1s2024-09-21!4m1!1i2!8m2!3d47.0457547!4d-122.8263881!16s%2Fg%2F1tj7398s?entry=ttu';

        }
    };

    return (
        <>
            <div className={"Lodging-Container"}>
                <div className={"Lodging-Wrapper"}>
                    <Reveal>
                        <div className={"Lodging-InformationContainer"}>
                            <div className={"Lodging-InformationHeader"}>
                                <h1>Secure your hotel accommodations now, as spaces are limited and
                                    expected to fill up quickly. Below are some discounted options. </h1>
                            </div>

                            <div className={"HotelList-Container"}>
                                <p>Discounted Hotel Rates (September 30 - October 4, 2024)</p>
                                <div className={"Hotel-Container"}>
                                    <ul> 1. TownPlace Suites by Marriott Tacoma Lakewood
                                        <li> 11725 Pacific Highway SW, Lakewood, WA 98499 </li>
                                        <li> $132 per night (30 rooms) </li>
                                        <li> (253) 582-1055</li>
                                    </ul>
                                    <img className={"Hotel-Image"} src={HotelOne} alt={"Hotel"} onClick={() =>handleClick(1)} />
                                </div>
                                <div className={"Hotel-Container"}>
                                    <ul> 2. Fairfield Inn & Suites by Marriott Tacoma DuPont
                                        <li> 1515 Wilmington Drive, DuPont, WA 98327 </li>
                                        <li> $132 per night + 13.5% tax (30 rooms) </li>
                                        <li> (360) 334-3314</li>
                                    </ul>
                                    <img className={"Hotel-Image"} src={HotelTwo} alt={"Hotel"} onClick={() =>handleClick(2)}/>
                                </div>
                                <div className={"Hotel-Container"}>
                                    <ul> 3. Courtyard Marriott Tacoma Downtown
                                        <li> 1515 Commerce Street, Tacoma, WA 98402 </li>
                                        <li> $169 per night + 13.5% tax (10 rooms, more can be booked) </li>
                                        <li> Parking: $19/night, Valet: $29/night</li>
                                        <li> (360) 334-3314</li>
                                    </ul>
                                    <img className={"Hotel-Image"} src={HotelThree} alt={"Hotel"} onClick={() =>handleClick(3)} />
                                </div>
                                <div className={"Hotel-Container"}>
                                    <ul> 4. La Quinta Inn by Wyndham Olympia - Lacey
                                        <li> 4704 Park Center Ave NE, Lacey, WA 98516 </li>
                                        <li> $100 per night (mention 50th Anniversary of 2-75) </li>
                                        <li> (360) 412-1200</li>
                                    </ul>
                                    <img className={"Hotel-Image"} src={HotelFour} alt={"Hotel"} onClick={() =>handleClick(4)}/>
                                </div>
                                <div className={"Hotel-Container"}>
                                    <ul> 5. Hampton Inn & Suites Olympia - Lacey
                                        <li> 4301 Martin Way E, Olympia, WA 98516</li>
                                        <li> $132 per night </li>
                                        <li> (360) 459-5000 </li>
                                    </ul>
                                    <img className={"Hotel-Image"} src={HotelFive} alt={"Hotel"} onClick={() =>handleClick(5)}/>
                                </div>
                                <div className={"Hotel-ContainerOther"}>
                                    <ul> 6. Holiday Inn Express & Suites Lacey - Olympia
                                        <li> 4460 3rd Ave SE, Lacey, WA 98503 </li>
                                        <li> $132 per night </li>
                                        <li> (360) 491-7985 </li>
                                    </ul>
                                    <img className={"Hotel-Image"} src={HotelSix} alt={"Hotel"} onClick={() =>handleClick(6)}/>
                                </div>

                            </div>
                        </div>
                    </Reveal>
                </div>
                <div className={"Lodging-Wrapper-Img"}>
                    <Reveal>
                        <div className={"Lodging-MapContainer"}>
                            <img className={"Lodging-Map"} src={LodgingMap} alt={'Lodging Map'}/>
                        </div>
                    </Reveal>
                </div>
                </div>

        </>
    );
}
export default Lodging