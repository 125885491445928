import React, {ChangeEvent, useEffect, useState} from "react";
import RangerScroll from "./Pictures/RangerScroll.webp";
import './AllTheCSS/Events.css';
import NewEventsBanner from './Pictures/NewEventsBanner.webp'
import {MagnifyGlassIcon} from "./icons/MagnifyGlassIcon";
import CustomEvent, {titleArr} from "./CustomEvents";


interface props {
    name: string
    setName: React.Dispatch<React.SetStateAction<string>>
    setPageSelector: React.Dispatch<React.SetStateAction<number>>
    pageSelector: number

}


const Events = ({pageSelector, setPageSelector,name, setName}: props) => {

    const [isSmallScreen, setIsSmallScreen] = useState(false)
    const [searchValue, setSearchValue] = useState<string>('')
    const [searchBarSelected, setSearchBarSelected] = useState(false)
    const url = new URL(window.location.href)



    useEffect(() => {
        setIsSmallScreen(window.innerWidth < 1200)
        window.scrollTo({top: 0, behavior: 'auto'});
        const handleResize = () => {
            const newWindowWidth = window.innerWidth;
            if (newWindowWidth < 1200) {
                setIsSmallScreen(true);
            } else {
                setIsSmallScreen(false)
            }
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    },[])

    const handleClick = (num: number) => {
        // ping('https://75thrangerregiment.org/2ndRangerBatAnniversary')
        window.open('/2ndRangerBatAnniversary', '_blank')
    }

    const handleChange = (input: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(input.target.value)

        titleArr.forEach((x) => {
            if (x.includes(input.target.value)) {
            }
        })
    }


    return(

        <>
            <div className={"Events-Container"} style={{backgroundImage:`url(${NewEventsBanner})`}} >
                <div style ={{marginTop: '4rem'}}> UPCOMING EVENTS</div>
            </div>
            <div className='EventsSearch-Container'>
                <div className={"EventsSearch-Icon-Container"}>
                    <MagnifyGlassIcon/>
                </div>
                <input
                    className= {`EventsSearch-Input ${searchBarSelected ? 'active' : 'notActive'}`}
                    type='text'
                    placeholder='SEARCH EVENTS'
                    value={searchValue}
                    onClick={() => setSearchBarSelected(true)}
                    onChange={handleChange}
                />
            </div>


            <div className={"Events-Filters"}>
                <div>UPCOMING</div>
                <div>YEAR</div>
                <div>MONTH</div>
            </div>
            <div className={"Event-All"}>
                <CustomEvent eventClick={() => handleClick(6)} eventPage={3} eventId={"one"} eventDayName={"TUES"} eventDay={"01"} eventMonth={"OCT"} eventTitle={"2ND RANGER BATTALION 50TH ANNIVERSARY"} eventDescription={"The 2nd Ranger Battalion welcomes all Rangers, past and present to join in on a day of reminiscing, reflecting, and honoring our legacy."} eventLocation={"Ranger Compound JBLM, WA"} eventTimeframe={"01 OCT - 03 OCT 2024 @0900"}/>

            </div>


        </>

    )
}



export default Events
