import React from "react";
import HamburgerIcon from "./Pictures/HamburgerIcon";
import AnniversaryLogo from "./Pictures/50thLogo.webp";
import './AllTheCSS/EventHeader.css';

interface props {
    setPageSelector: (num:number) => void
    setMobileDropDown: React.Dispatch<React.SetStateAction<boolean>>
    eventTitle: string
    eventImg: string
    isSmallScreen: boolean
}

const EventHeader = ({setPageSelector, eventImg, eventTitle, setMobileDropDown, isSmallScreen}: props ) => {

    const handleClick = (num: number) => {
        setPageSelector(num)
    }


    if (isSmallScreen) {
        return (
            <div className={"EventHeader-Container"}
                 style={{backgroundPosition: "center top", backgroundImage: `url(${eventImg})`}}>
                <div className={"EventHeader-LogoContainer"} onClick={() => setPageSelector(0)}>
                    <img className={"EventHeader-50thLogo"} src={AnniversaryLogo} alt={"50thAnniversaryLogo"} />
                </div>

                <div className={"EventHeader-Title"} style={{alignItems: "center"}}>{eventTitle}</div>

                <div className={"EventHeader-Hamburger"} onClick={() => setMobileDropDown(true)}>{HamburgerIcon("white")}</div>
            </div>
        )
    } else {
        return (
            <div className={"EventHeader-Container"}
                 style={{backgroundPosition: "center top", backgroundImage: `url(${eventImg})`}}>
                <div className={"EventHeader-LogoContainer"} onClick={() => setPageSelector(0)}>
                    <img className={"EventHeader-50thLogo"} src={AnniversaryLogo} alt={"50thAnniversaryLogo"}/>
                </div>

                <div className={"EventHeader-Title"} style={{alignItems: "center"}}>{eventTitle}</div>
                <div className={"EventHeader-TopNav"}>
                    <span className={"EventHeader-TopNavName"} onClick={() => setPageSelector(0)}>HOME</span>
                    <span className={"EventHeader-TopNavName"} onClick={() => handleClick(1)}>EVENTS</span>
                    <span className={"EventHeader-TopNavName"} onClick={() => handleClick(2)}>EVENT MAP</span>
                    <span className={"EventHeader-TopNavName"} onClick={() => handleClick(3)}>JBLM ACCESS</span>
                    <span className={"EventHeader-TopNavName"} onClick={() => handleClick(4)}>PARKING</span>
                    <span className={"EventHeader-TopNavName"} onClick={() => handleClick(5)}>DINING</span>
                    <span className={"EventHeader-TopNavName"} onClick={() => handleClick(6)}>LODGING</span>
                    <span className={"EventHeader-TopNavName"} onClick={() => handleClick(7)}>SPONSORS</span>
                </div>
            </div>
        )

    }
}

export default EventHeader