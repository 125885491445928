import CountDown from "./CountDown";
import "./AllTheCSS/SecondRangerAnniversary.css"
import React, {useEffect, useState} from "react";
import AnniversaryHero from "./Pictures/AnniversaryHero.webp";
import SecondBatScroll from "./Pictures/2ndBatTabAndScroll.webp"
import AnniversaryLogo from "./Pictures/50thLogo.webp"
import MapButton from "./Pictures/MapButton";
import TimelineButton from "./Pictures/TimelineButton";
import FoodButton from "./Pictures/FoodButton";
import HotelButton from "./Pictures/HotelButton";
import ParkingButton from "./Pictures/ParkingButton";
import AccessButton from "./Pictures/AccessButton";
import RSVPButton from "./Pictures/RSVPButton";
import Dining from "./Dining";
import {redirectToExternalSite} from "./Helpers";
import ContinueButton from "./Pictures/ContinueButton";
import HamburgerIcon from "./Pictures/HamburgerIcon";
import AnniversaryHelo from "./Pictures/AnniversaryHelo";
import MobileMenuBackground from "./Pictures/mobileMenuBackgroundPic.webp"
import TwoDiamond from "./Pictures/_2_rugged.webp"
import CloseIcon from "./Pictures/CloseIcon";
import {useNavigate, useLocation} from "react-router-dom";
import DiningHeader from "./Pictures/DiningHeader.webp"
import SponsorsHeader from "./Pictures/SponsorsHeader.webp";
import LodgingHeader from "./Pictures/LodgingHeader.webp"
import Sponsors from "./Sponsors";
import Lodging from "./Lodging";
import Access from "./Access";
import EventMap from "./EventMap";
import EventHeader from "./EventHeader";
import EventMapHeader from "./Pictures/headerBackgroundEventMap.webp"
import JBLMAccess from "./Pictures/headerBackgroundJBLMAccess.webp"
import TimelinePic from "./Pictures/headerBackgroundTimeline.webp"
import TmRainierSmall from './Pictures/TmRainierLogoSmall.webp'
import CreatedBySmall from './Pictures/CreatedBySmall.webp'
import Timeline from "./Timeline";
import Parking from "./Parking";
import Reveal from "./Reveal";
import LiveStreamButton from "./Pictures/LiveStreamButton";



const SecondRangerAnniversary = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(false)
    const [anniversaryPageSelector, setAnniversaryPageSelector] = useState(0)
    const [isOpenModel, setIsOpenModel] = useState(true)
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
    const url = new URL(window.location.href)
    const navigate = useNavigate()
    const location = useLocation();

    useEffect(() => {
        if (isMobileMenuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isMobileMenuOpen]);


    //make header th same

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const number = params.get('number');
        if (number) {
            setAnniversaryPageSelector(Number(number));
        } else {
            setAnniversaryPageSelector(0)
        }
    }, [location]);


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const number = params.get('number');
        if (number) {
            setAnniversaryPageSelector(Number(number));
        } else {
            setAnniversaryPageSelector(0)
        }
    }, [url.pathname]);

    useEffect(() => {
        setIsMobileMenuOpen(false)
    },[anniversaryPageSelector])


    useEffect(() => {
        setIsSmallScreen(window.innerWidth <= 900 || window.innerHeight <= 600)
        window.scrollTo({top: 0, behavior: 'auto'});
        const handleResize = () => {
            const newWindowWidth = window.innerWidth;
            const newWindowHeight = window.innerHeight;
            if (newWindowWidth <= 900 || newWindowHeight <= 600) {
                setIsSmallScreen(true);
            } else {
                setIsSmallScreen(false)
            }
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    },[])

    const handleSelectNumber = (number: number) => {
        setAnniversaryPageSelector(number);
        navigate({ search: `?number=${number}` });
    };

    const openingModel = () => {
            return (
                <>
                    <Reveal>
                        <div className={'openingModel-container'}>
                            <div className={'openingModel-header'}>
                                WELCOME!
                            </div>
                            <div className={'openingModel-text'}>
                                Calling all 2/75 Rangers past and present, active duty or retired to 2nd Ranger
                                Battalion’s 50th Anniversary at Joint Base Lewis McChord from 1 October, 2024 - 3
                                October, 2024. This website will serve as the primary venue for distribution of all
                                information pertaining to this celebration. All Rangers, regardless of your Battalion
                                affiliation or time served in the Regiment, are encouraged to attend if you are in the
                                Pacific Northwest in early October. For any and all visitors, please utilize the RSVP button
                                below or at the top of this page so we can adequately plan for your arrival.

                            </div>
                            <div className={'openingModel-text-con'}>
                                In order to access more information regarding the daily schedule and events,
                                proceed below.
                            </div>
                            <div className={'openingModel-cont'} >
                                {ContinueButton(()=> setIsOpenModel(false))}
                            </div>
                        </div>
                    </Reveal>
                    <div className={"theModalVoid"}/>
                </>
            )
    }

    const mobileMenu = () => {
        return (
            <div className={ `Anniversary-MobileDropDown-Option-Container ${isMobileMenuOpen ? 'active' : 'notActive'}`} style={{backgroundImage:`url(${MobileMenuBackground})`}}>
                <div className={"Anniversary-MobileDropDown-Header"}>
                    <img className={"Anniversary-MobileDropDown-pic"} src={AnniversaryLogo} alt={"50thAnniversaryLogo"}/>
                    <div onClick={() => setIsMobileMenuOpen(false)}>
                        <div className={"Anniversary-MobileDropDown-Close"}><CloseIcon/></div>
                    </div>
                </div>
                <div style={{overflow:"scroll", scrollbarWidth:"none", marginBottom:"8rem"}}>
                    <div className={'Anniversary-MobileDropDown-Option'} onClick={() => handleSelectNumber(0)}>HOME</div>
                    <div className={'Anniversary-MobileDropDown-Option'} onClick={() => handleSelectNumber(1)}>EVENTS</div>
                    <div className={'Anniversary-MobileDropDown-Option'} onClick={() => handleSelectNumber(2)}>EVENT MAP</div>
                    <div className={'Anniversary-MobileDropDown-Option'} onClick={() => handleSelectNumber(3)}>JBLM BASE ACCESS</div>
                    <div className={'Anniversary-MobileDropDown-Option'} onClick={() => handleSelectNumber(4)}>PARKING</div>
                    <div className={'Anniversary-MobileDropDown-Option'} onClick={() => handleSelectNumber(5)}>DINING</div>
                    <div className={'Anniversary-MobileDropDown-Option'} onClick={() => handleSelectNumber(6)}>LODGING</div>
                    <div className={'Anniversary-MobileDropDown-Option'} onClick={() => handleSelectNumber(7)}>SPONSORS</div>
                </div>
                <img className={"mobile-twoDiamond"} src={TwoDiamond} alt={"2 diamond"}/>
            </div>
        )
    }


    switch (anniversaryPageSelector){
        default:
            return (


                <div className={'anniversary-container'}>
                    {isOpenModel && openingModel()}
                    <div className={"anniversary-mainPage-container"} style={{backgroundImage:`url(${AnniversaryHero})`}}>
                        {isSmallScreen && <AnniversaryHelo/>}
                        {mobileMenu()}
                        {isMobileMenuOpen &&
                            <>
                                <div className={"theModalVoid"} onClick={() => setIsMobileMenuOpen(false)}/>
                            </>
                        }
                        <div className={"anniversary-header-container"}>
                            <img className={"anniversary-logo"} src={AnniversaryLogo} alt={"50thAnniversaryLogo"}/>
                            {!isSmallScreen ? <div className={"anniversary-header-options"}>
                                    <div className={"headerNames"} onClick={() => handleSelectNumber(0)}>
                                        HOME
                                    </div>
                                    <div className={"headerNames"} onClick={() => handleSelectNumber(1)}>
                                        EVENTS
                                    </div>
                                    <div className={"headerNames"} onClick={() => handleSelectNumber(2)}>
                                        EVENT MAP
                                    </div>
                                    <div className={"headerNames"} onClick={() => handleSelectNumber(3)}>
                                        JBLM ACCESS
                                    </div>
                                    <div className={"headerNames"} onClick={() => handleSelectNumber(4)}>
                                        PARKING
                                    </div>
                                    <div className={"headerNames"} onClick={() => handleSelectNumber(5)}>
                                        DINING
                                    </div>
                                    <div className={"headerNames"} onClick={() => handleSelectNumber(6)}>
                                        LODGING
                                    </div>
                                    <div className={"headerNames"} onClick={() => handleSelectNumber(7)}>
                                        SPONSORS
                                    </div>
                            </div> :
                                <div className={'anniversary-hamburger'} onClick={() => setIsMobileMenuOpen(true)}>
                                    {HamburgerIcon("black")}
                                </div>
                            }
                        </div>
                            <div className={"anniversary-countdown-container"}>
                                {!isSmallScreen && <img className={'anniversary-rangerScroll'} src={SecondBatScroll} alt={'rangerScroll'}/>}
                                <span>COUNTDOWN TO THE 50TH</span>
                                <CountDown/>
                                {isSmallScreen && <img className={'anniversary-rangerScroll'} src={SecondBatScroll} alt={'rangerScroll'}/>}


                                {/*<span className={"viewWelcomeMsg"} onClick={() => setIsOpenModel(true)}>View Welcome Message...</span>*/}
                                {/*<div className={"anniversary-text"}>*/}
                                {/*    <p>Calling all 2/75 Rangers past and present, active duty or retired to 2nd Ranger Battalion’s 50th Anniversary at Joint Base Lewis McChord from 1 October,*/}
                                {/*        2024 - 3 October, 2024. This website will serve as the primary venue for distribution of all information pertaining to this celebration.*/}
                                {/*        All Rangers, regardless of your Battalion affiliation or time served in the Regiment, are encouraged to attend if you are in the Pacific Northwest in early October.*/}
                                {/*        For any and all visitors, please utilize the RSVP button below or at the top of this page so we can adequately plan for your arrival.*/}
                                {/*    </p>*/}
                                {/*    <p>*/}
                                {/*        In order to access more information regarding the daily schedule and events, simply click the corresponding orange two diamond below for details.*/}
                                {/*    </p>*/}
                                {/*</div>*/}
                            </div>
                            <div className={"all-diamondButton-container"}>
                                {LiveStreamButton(() =>  window.open("https://www.instagram.com/2ndrangerbattalion/?hl=en"))}
                                <div className={"anniversary-button-container"}>
                                    {TimelineButton(() => handleSelectNumber(1), isOpenModel)}
                                    {MapButton(() => handleSelectNumber(2), isOpenModel)}
                                    {AccessButton(() => handleSelectNumber(3), isOpenModel)}
                                    {ParkingButton(() => handleSelectNumber(4), isOpenModel)}
                                    {FoodButton(() => handleSelectNumber(5), isOpenModel)}
                                    {HotelButton(() => handleSelectNumber(6), isOpenModel)}
                                </div>
                            </div>
                    </div>
                </div>
            )
        case 1:
            return  <>
                {mobileMenu()}
                {isMobileMenuOpen &&
                        <div className={"theModalVoid"} onClick={() => setIsMobileMenuOpen(false)}/>
                    }
                <EventHeader setPageSelector={handleSelectNumber}
                             setMobileDropDown={setIsMobileMenuOpen}
                             eventTitle={'Events'}
                             eventImg={TimelinePic}
                             isSmallScreen={isSmallScreen}/>
                <Timeline/>
            </>
        case 2:
            return <>
                {mobileMenu()}
                {isMobileMenuOpen &&
                        <div className={"theModalVoid"} onClick={() => setIsMobileMenuOpen(false)}/>
                    }
                <EventHeader setPageSelector={handleSelectNumber}
                             setMobileDropDown={setIsMobileMenuOpen}
                             eventTitle={'Event Map'}
                             eventImg={EventMapHeader}
                             isSmallScreen={isSmallScreen}/>
                <EventMap/>
            </>
        case 3:
            return<>
                {mobileMenu()}
                {isMobileMenuOpen &&
                        <div className={"theModalVoid"} onClick={() => setIsMobileMenuOpen(false)}/>
                    }
                <EventHeader setPageSelector={handleSelectNumber}
                             setMobileDropDown={setIsMobileMenuOpen}
                             eventTitle={'JBLM ACCESS'}
                             eventImg={JBLMAccess}
                             isSmallScreen={isSmallScreen}/>
                <Access/>
            </>
        case 4:
            return <>
                {mobileMenu()}
                {isMobileMenuOpen &&
                    <div className={"theModalVoid"} onClick={() => setIsMobileMenuOpen(false)}/>
                }
                <EventHeader setPageSelector={handleSelectNumber}
                             setMobileDropDown={setIsMobileMenuOpen}
                             eventTitle={'Parking'}
                             eventImg={JBLMAccess}
                             isSmallScreen={isSmallScreen}/>
                <Parking/>
            </>
        case 5:
            return(
                <>
                    {mobileMenu()}
                    {isMobileMenuOpen &&
                        <div className={"theModalVoid"} onClick={() => setIsMobileMenuOpen(false)}/>
                    }
                    <EventHeader setPageSelector={handleSelectNumber}
                                 setMobileDropDown={setIsMobileMenuOpen}
                                 eventTitle={"DINING"}
                                 eventImg={DiningHeader}
                                 isSmallScreen={isSmallScreen} />
                    <Dining/>
                </>

        )
        case 6:
            return(
                <>
                    {mobileMenu()}
                    {isMobileMenuOpen &&
                    <>
                        <div className={"theModalVoid"} onClick={() => setIsMobileMenuOpen(false)}/>
                    </>
                    }
                    <EventHeader setPageSelector={handleSelectNumber}
                                 setMobileDropDown={setIsMobileMenuOpen}
                                 eventTitle={"LODGING"}
                                 eventImg={LodgingHeader}
                                 isSmallScreen={isSmallScreen}/>
                    <Lodging/>
                </>
            )
        case 7:
            return(
                <>
                    {mobileMenu()}
                    {isMobileMenuOpen &&
                        <div className={"theModalVoid"} onClick={() => setIsMobileMenuOpen(false)}/>
                    }
                    <EventHeader setPageSelector={handleSelectNumber}
                                 setMobileDropDown={setIsMobileMenuOpen}
                                 eventTitle={"SPONSORS"}
                                 eventImg={SponsorsHeader}
                                 isSmallScreen={isSmallScreen}/>
                    <Sponsors/>
                </>
            )

    }



}
export default SecondRangerAnniversary