import React, {useEffect, useRef, useState} from "react";
import './AllTheCSS/About.css'
import missionAndVision from './Pictures/MissionAndVision.webp'
import './AllTheCSS/Home.css'
import theBattalionsPicture from "./Pictures/TheBattalionsPicture.webp";
import whereInTheWorld from "./Pictures/WhereInTheWorld.webp";
import lifeinReg from "./Pictures/LifeInRegiment1.webp";
import batScrolls from "./Pictures/BatScrolls123.webp";
import batScrollsRS from "./Pictures/batscrollsSTBRMIB.webp"
import lifeexpand1 from "./Pictures/LifeInRegimentEx1.webp";
import lifeInRegiment2 from "./Pictures/LifeInRegiment2.webp";
import phalanxpic from "./Pictures/phalanxpic.webp"
import familyLife from "./Pictures/familyLife.webp"
import professionalPic from "./Pictures/ProfessionalPic.webp";
import CustomButton from "./CustomButton";
import Reveal from "./Reveal";

interface props {
    name: string
}

const About = ({name}: props) => {
    const [showDiv, setShowDiv] = useState([false, false])
    const [number, setNumber] = useState(20)
    const topOfMission = useRef<HTMLDivElement>(null)
    const topOfTheBattalions = useRef<HTMLDivElement>(null)
    const topOfLifeOfRegiment = useRef<HTMLDivElement>(null)
    const dropDownOne = useRef<HTMLDivElement>(null)
    const dropDownTwo = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (name === "mission" && topOfMission.current) {
                topOfMission.current.scrollIntoView({behavior:'smooth'})
            } else if (name === "battalions" && topOfTheBattalions.current) {
                topOfTheBattalions.current.scrollIntoView({behavior:'smooth'})
            } else if (name === "LifeInReg" && topOfLifeOfRegiment.current) {
                console.log('are we hitting?');
                topOfLifeOfRegiment.current.scrollIntoView({behavior:'smooth'})
            }
        }, 100)
        return () => {
            clearTimeout(timeoutId)
        }

    },[name])

    useEffect(() => {
        if (dropDownOne.current && showDiv[0] && number === 0) {
            dropDownOne.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        } else if (dropDownTwo.current && showDiv[1] && number === 1) {
            dropDownTwo.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
    },[showDiv])

    const handleClick = (num: number) => {
        setNumber(num)
        const updatedShowDiv = [...showDiv]
        updatedShowDiv[num] = !updatedShowDiv[num]
        setShowDiv(updatedShowDiv)
    }

    return (
        <>
            <div ref={topOfMission} className={'MissionAndVision-Container'} style={{backgroundImage:`url(${missionAndVision})`}}>
                <div className={'About-header'}>
                    MISSION AND VISION
                </div>

                    <div className={'MissionAndVision-Text'}>
                        <Reveal>
                            <>
                                <p className={'MissionAndVision-Text-RLTW'}>Rangers Lead The Way</p>
                                The 75th Ranger Regiment is the worlds premier direct action raid force and provides our Nation a
                                range of capabilities not found in any other Special Operations or Conventional Force.
                                Simultaneously, we conduct large-scale Joint Forcible Entry Operations and execute surgical
                                Special Operations Raids around the globe in high-risk, uncertain, and politically sensitive areas.
                            </>
                        </Reveal>
                    </div>
            </div>
            <div ref={topOfTheBattalions} className={'About-Container'}
                 style={{ backgroundImage: `url(${theBattalionsPicture})`,backgroundSize: "cover"}}>
                <div className={'About-container-div'}>
                    THE BATTALIONS
                </div>
                    <div className={'About-Paragraph'}>
                        <Reveal>
                            <div className={'About-Paragraph-Container'}>
                                <div className={'LifeInRegiment-Container-Paragraph-div'}>
                                    Each battalion within the 75th Ranger Regiment is highly trained, well-equipped, and prepared to execute a wide range of missions with precision and effectiveness,
                                    contributing to the regiment's reputation as one of the most elite special operations forces in the world.
                                </div>
                                <div>
                                    <CustomButton onClick={()=>{handleClick(0)}}>LEARN MORE</CustomButton>
                                </div>
                            </div>
                        </Reveal>
                    </div>
            </div>
                {showDiv[0] && (
                <div className={'TheBattalionInfo-Container'} ref={dropDownOne}>
                    <div className={'TheBattalionInfo-Content'}>
                        <img src={whereInTheWorld}/>
                    </div>
                    <div className={'TheBattalionInfo-Content1'}>
                        <div className={'TheBattalionInfo-Content2'}>
                            <img className={'batScrolls'} src={batScrolls} alt={'battalionScrolls'}/>
                        </div>
                        <div className={'TheBattalionInfo-Paragraphs'}>
                            <p> <b>1st, 2nd, and 3rd Ranger Battalions</b> are known as “Line Battalions” and are specialized infantry units.
                                These battalions are elite forces known for their exceptional combat skills, versatility, and ability
                                to conduct a variety of missions,
                                including direct-action raids, special reconnaissance, airfield seizures,
                                counterterrorism operations and ability to rapidly deploy to anywhere in the world in 18 hours.
                            </p>
                            <p>They are characterized by their proficiency in small-unit tactics, marksmanship,
                                physical fitness, and leadership abilities. Ranger Battalions are often at the forefront
                                of special operations and are deployed worldwide to execute critical missions in
                                support of national security objectives.
                            </p>
                        </div>

                    </div>
                    <div className={'TheBattalionInfo-Content1'}>
                        <div className={'TheBattalionInfo-Paragraphs'}>
                            <p>
                                <b>RSTB</b> stands for "<b>Regimental Special Troops Battalion</b>," which is a specialized battalion within the 75th Ranger Regiment responsible for providing essential support functions and
                                specialized capabilities to the entire regiment. This includes intelligence, reconnaissance, communications, and other critical support functions necessary for the regiment's operations.
                            </p>
                            <p>  <b>RMIB</b> stands for "<b>Ranger Military Intelligence Battalion</b>," another unit within the 75th Ranger Regiment.
                                The RMIB specializes in military intelligence, surveillance, reconnaissance, cyber, and electronic warfare capabilities, to support the regiment's missions.
                                They gather and analyze intelligence to inform decision-making and enhance the operational effectiveness of the Rangers.
                            </p>
                        </div>
                        <div className={'TheBattalionInfo-Content2'}>
                            <img className={'batScrolls'} src={batScrollsRS} alt={'battalionScrolls'}/>
                        </div>

                    </div>
                </div>
                )}
            <div ref={topOfLifeOfRegiment} className={'About-Container'} style={{backgroundImage: `url(${lifeinReg})`,backgroundSize: "cover"}}>
                <div className={'About-container-div'}>
                    LIFE IN REGIMENT
                </div>
                <div className={'About-Paragraph'}>
                    <Reveal>
                        <div className={'About-Paragraph-Container'}>
                        <div className={'LifeInRegiment-Container-Paragraph-div'}>
                            Being a Ranger is an honor shared by a distinct few. In order to become one,
                            you must pass a series of grueling tests that will push the mind and body to the limit, but it does not stop there.
                            Rangers take pride in constantly sharpening themselves not only for battle but in all aspects of life.
                        </div>
                        <div>
                            <CustomButton  onClick={()=>{handleClick(1)}}> LEARN MORE</CustomButton>
                        </div>
                            </div>
                    </Reveal>

                </div>
            </div>
            {showDiv[1] && (
                <div className={'LifeInRegiment-expanded'} ref={dropDownTwo}>
                    <div className={'life5'}>
                        <div className={'life5-div'}>
                            <div className={'life5-header'}>PROFESSIONAL DEVELOPMENT OPPORTUNITIES</div>
                            <p>
                                No matter the MOS, all rangers receive world class training opportunities to help them become the subject matter experts
                                in their respective fields and afforded the opportunity to earn certifications to reflect that.
                            </p>
                            <p>Certifications and training include but are not limited to:</p>
                            <div className={'life5-listContainer'}>
                                <div>
                                    <ul style={{paddingInlineStart:'0'}}>
                                        <li style={{listStyleType:'none'}}>Mechanical and Logistics
                                            <ul style={{paddingLeft:'1.5rem'}}>
                                                <li>Universal Technical Institute<br/> (electrical, engine repair, transmission etc.) </li>
                                                <li>Defensive Drivers Course</li>
                                                <li>FoSOV</li>
                                                <li>Special Operations Logistics Course</li>
                                            </ul>
                                        </li>


                                    </ul>
                                    <ul style={{paddingInlineStart:'0'}}>
                                        <li style={{listStyleType:'none'}}>
                                            Medical
                                            <ul style={{paddingLeft:'1.5rem'}}>
                                                <li>NREMT</li>
                                                <li>Associates in Health Science</li>
                                                <li>Special Operations Advanced Tactical Practitioner</li>
                                            </ul>
                                        </li>
                                    </ul>

                                </div>
                                <div>
                                    <ul style={{paddingInlineStart:'0'}}>
                                        <li style={{listStyleType:'none'}}>
                                            Technical and Human Resources
                                            <ul style={{paddingLeft:'1.5rem'}}>
                                                <li>SEC+ </li>
                                                <li>CISSP</li>
                                                <li>CISM</li>
                                                <li>Microsoft Office Specialist</li>
                                                <li>PMP</li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <ul style={{paddingInlineStart:'0'}}>
                                        <li style={{listStyleType:'none'}}>
                                            Cook
                                            <ul style={{paddingLeft:'1.5rem'}}>
                                                <li>Culinary Institute of America</li>
                                                <li>Austere Cooking Environment</li>
                                            </ul>
                                        </li>
                                    </ul>


                                </div>
                            </div>
                        </div>
                        <div className={'life5-pic'}>
                            <img className={'about-img-container'} src={professionalPic} alt={'mechanic'}/>
                        </div>
                    </div>
                    <div className={'life4'}>
                        <div className={'life4-pic'}>
                            <img className={'about-img-container'} src={phalanxpic} alt={'phalanx'} />
                        </div>
                        <div className={'life4-div'}>
                            <h1 className={'life2-header'}>RANGER FOR LIFE</h1>
                            <div>
                                <ul>
                                    <p>
                                        <b>All Rangers</b> are able to invest in themselves and their
                                        future through the <b>PHALANX</b> program focusing on three pillars:
                                    </p>
                                    <li>
                                        Human Performance
                                    </li>
                                    <ul>
                                        <li style={{fontWeight:'normal'}}>
                                            Performance enhancement opportunities,
                                            holistic health assessments,
                                            world-class offsite care tailored to each individual.
                                        </li>
                                    </ul>
                                    <li>
                                        Career
                                    </li>
                                    <ul>
                                        <li style={{fontWeight:'normal'}}>
                                            Mastery of Ranger fundamentals and competences,
                                            more time in key positions — a minimum of 18 months —
                                            credentials for Ranger training
                                        </li>
                                    </ul>
                                    <li>
                                        Education
                                    </li>
                                    <ul>
                                        <li style={{fontWeight:'normal'}}>
                                            Executive-level education resources, professional certifications and
                                            vocational training - that would translate into more opportunities in civilian life -
                                            battalion education counselors - a rough equivalent
                                            of academic advisors in universities and degree pathways tailored to the individual
                                        </li>
                                    </ul>
                                    <p>
                                        It builds upon the Ranger For Life(RFL) program, aiming to enhance Rangers'
                                        success within and beyond the regiment.
                                    </p>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={'life1'}>
                        <div className= {'life1-div'}>
                            <h1 className={'life1-div-header'}> LIFE DEPLOYED</h1>
                            <div className={'life1-div-content'}>
                            <div><p>
                                Day-to-day life deployed with the 75th Ranger Regiment is faster-paced than that of a conventional
                                Army unit or other Special Operation units.
                            </p>
                           </div>
                           <div>
                                While deployments may vary, Ranger units typically undertake a higher frequency of missions.
                                As a Special Operations unit, these missions are more sensitive and take a higher priority.
                            </div>
                            </div>
                        </div>
                        <div className={'life1-pic'}>
                            <img className={'about-img-container'} src={lifeexpand1} alt={'Guy with Gun'}/>
                        </div>
                    </div>
                    <div className={'life2'}>
                            <div className={'life2-pic'}>
                            <img className={'about-img-container'} src={lifeInRegiment2} alt={'Gym'}/>
                            </div>
                            <div className={'life2-div'}>
                                <h1 className={'life2-header'}>LIFE ON BASE</h1>
                                <div className={'life2-content'}>
                                    <div>
                                        <p>
                                        Rangers are stationed at one of three bases in the United States: Fort Moore, Hunter Army Airfield, and Joint Base Lewis McChord.
                                        </p>
                                        <p>
                                         Each Ranger Battalion has the ability to deploy with
                                         18-hours notice. For that reason, Ranger units have an intense focus on Physical Fitness (PT).
                                        </p>
                                        <p>
                                            Rangers must also undergo individualized training, which can include shooting on the range, parachute jumps, or medical training.
                                            They must take part in platoon evaluations and battle drills that test critical Ranger skills.
                                        </p>
                                        <p>
                                             Aside from the demanding training schedule, day-to-day life in the Ranger Regiment is similar to any typical Army unit. Rangers receive 30-days of leave per year,
                                            training holidays, federal holidays and long weekends. They are also able to attend military and civilian schools that compliment their military occupational specialties.
                                        </p>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className={'life3'}>
                        <div className={'life3-div'}>
                            <h1 className={'life3-header'}> YOUR FAMILY</h1>
                            <div className={'life3-content'}>
                                <div>
                                   <p> The Rangers have one of the best Family Readiness Groups in the Army.
                                    Families of spouses are invited to attend many unit functions and they
                                    receive routine updates from the chain of command regarding deployments
                                    and training exercises.
                                   </p>

                                    <p>
                                    While the pace of life in the Regiment is faster than that of most
                                    conventional units, the leadership understands that balance and predictability
                                    are important qualities of family life.
                                    </p>

                                   <p>
                                    No one can predict Ranger deployment, but family members will receive up to
                                    date information about deployment and training timelines whenever possible.
                                   </p>

                                    <p>
                                    Ranger families have access to dedicated doctors for care. They also have access
                                    to special Ranger facilities and enjoy a wide range of special regimental family
                                    activities and events.
                                    </p>
                                    <p>
                                    Professional civilian family readiness advisors work full-time at each
                                    regimental unit to ensure families have the skills and support they need
                                    to be prepared for challenges of deployment. Dedicated Ranger chaplains are
                                    always available to provide counseling.
                                    </p>

                                    Ranger spouses are often surprised to find that the friendships they develop
                                    with other Ranger families form lifelong bonds.
                                </div>
                            </div>
                        </div>
                        <div className={'life3-pic'}>
                            <img style={{height:'auto', width:"auto", maxWidth:'100%', maxHeight:'50rem'}} src={familyLife} alt={'family'}/>
                        </div>
                    </div>

                </div>

            )}
        </>
    )
}
export default About;