import React from "react";

interface prop {
    isUp:boolean
    color?:string
}

const ExpandIcon = ({isUp, color}: prop) => {
    const rotation = color ? 0 : isUp ? 270 : 90
    return (
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <g clip-path="url(#clip0_11132_89018)">
                <path d="M22.668 17L14.168 8.5L12.1705 10.4975L18.6588 17L12.1705 23.5025L14.168 25.5L22.668 17Z" fill={`${color ? color : "#C72727"}`} transform={`rotate(${rotation} 17 17)`}/>
            </g>
            <defs>
                <clipPath id="clip0_11132_89018">
                    <rect width="34" height="34" fill="white" transform="matrix(0 1 -1 0 34 0)" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default ExpandIcon