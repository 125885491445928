import React, {useEffect, useState} from 'react';
import About from "./About";
import CareerPath from "./CareerPath";
import Connect from "./Connect";
import FAQ from "./FAQ";
import Home from "./Home";
import './AllTheCSS/App.css';
import Footer from "./Footer";
import Header from "./Header";
import {Routes, Route, useNavigate} from "react-router-dom";
import SecondRangerAnniversary from "./SecondRangerAnniversary";
import Events from "./Events";



function App() {
    const [dropDown, setDropDown] = useState(0)
    const [name, setName] = useState('')
    const [mobileDropDown, setMobileDropDown] = useState(false)
    const navigate = useNavigate()
    const url = new URL(window.location.href)

    const handleClick = (value: number, name:string) => {
        setName(name)
        setPageSelector(value)
        setDropDown(0)
        setMobileDropDown(false)
    }

    const setPageOnRefresh = (url:URL) => {
        switch(url.pathname) {
            case '/About':
                return 1
            case '/CareerPath':
                return 2
            case '/Connect':
                return 3
            case'/FAQ':
                return 4
            case'/Events':
                return 5
            case'/2ndRangerBatAnniversary':
                return 6
            default:
                return 0
        }
    }
    const [pageSelector, setPageSelector] = useState(setPageOnRefresh(url))

    //updates pageSelector correctly when user clicks back button
    useEffect(() => {
        setPageSelector(setPageOnRefresh(url));
    }, [url.pathname]);


    useEffect(() => {
        if(name === '') {
            window.scrollTo({top: 0, behavior: 'auto'});
        }
        switch (pageSelector) {
            case 1:
                navigate('/About')
                break
            case 2:
                navigate('/CareerPath')
                break
            case 3:
                navigate('/Connect')
                break
            case 4:
                navigate('/FAQ')
                break
            case 5:
                navigate('/Events')
                break
            case 6:
                navigate('/2ndRangerBatAnniversary')
                break
            default:
                navigate('')
        }
    },[pageSelector, name])

    useEffect(() => {

        const userScrolled = () => {
            if (window.scrollY === 0 && pageSelector !== 3) {
                setName('')
            }
        }
        window.addEventListener('scroll', userScrolled)

        return () => {
            window.removeEventListener('scroll', userScrolled)
        }
    },[])

    return (
          <div className={'App-Container'}>
              {pageSelector != 6 &&
              <Header
                  handleClick={handleClick}
                  dropDown={dropDown}
                  setDropDown={setDropDown}
                  mobileDropDown={mobileDropDown}
                  setMobileDropDown={setMobileDropDown}
              />
              }
              <div className={'App-Content'}>
                  {dropDown !== 0 || mobileDropDown ? <div className={'overlay'}/> : <></>}
                  <Routes>
                      <Route path={''} element={<Home setPageSelector={setPageSelector}/>}/>
                      <Route path={'/About'} element={<About name={name}/>}/>
                      <Route path={'/CareerPath'} element={<CareerPath name={name} setName={setName}/>}/>
                      <Route path={'/Connect'} element={<Connect name={name} setPageSelector={setPageSelector}/>}/>
                      <Route path={'/FAQ'} element={<FAQ name={name} setName={setName} setPageSelector={setPageSelector}/>}/>
                      <Route path={'/Events'} element={<Events name={name} setName={setName} pageSelector={pageSelector} setPageSelector={setPageSelector}/>}/>
                      <Route path={'/2ndRangerBatAnniversary'} element={<SecondRangerAnniversary/>}/>
                  </Routes>
                  {pageSelector != 6 &&
                    <Footer handleClick={handleClick} pageSelector={pageSelector} setPageSelector={setPageSelector}/>
                  }
              </div>
          </div>
    )
}

export default App;
