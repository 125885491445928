import React, {ChangeEvent, useEffect, useState} from "react";
import RangerScroll from "./Pictures/RangerScroll.webp";
import './AllTheCSS/Parking.css';
import NewEventsBanner from './Pictures/NewEventsBanner.webp'
import compoundParking from './Pictures/CompoundParking.webp'
import overFlowParking from './Pictures/OverflowParking.webp'
import batScrolls from "./Pictures/BatScrolls123.webp";
import mapPin from './Pictures/MapPin.webp'
import {redirect} from "react-router-dom";
import EventHeader from "./EventHeader";
import Reveal from "./Reveal";

interface props {
    name: string
    setName: React.Dispatch<React.SetStateAction<string>>
    setPageSelector: React.Dispatch<React.SetStateAction<number>>
    pageSelector: number


}

const Parking = () => {
    const handleClick = () => {


    };

    const redirectToMap = (site:string) => {
        switch(site) {
            case "CMPD PARKING":
                window.open("https://www.google.com/maps/place/47%C2%B004'23.2%22N+122%C2%B034'10.0%22W/@47.0731146,-122.5720069,779m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d47.073111!4d-122.569432?entry=ttu")
                break
            case "LOT 1":
                window.open("https://www.google.com/maps/place/47%C2%B004'28.8%22N+122%C2%B034'12.9%22W/@47.0746698,-122.5715137,390m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d47.074668!4d-122.570244?entry=ttu")
                break
            case "LOT 2":
                window.open("https://www.google.com/maps/place/47%C2%B004'28.8%22N+122%C2%B034'04.4%22W/@47.0746588,-122.5691695,390m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d47.074657!4d-122.567882?entry=ttu")
                break
            case "LOT 3":
                window.open("https://www.google.com/maps/place/47%C2%B004'59.8%22N+122%C2%B033'59.7%22W/@47.0832838,-122.5678765,390m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d47.083282!4d-122.566589?entry=ttu")
                break
            case "LOT 4":
                window.open("https://www.google.com/maps/place/47%C2%B005'12.7%22N+122%C2%B034'00.6%22W/@47.0868478,-122.5681255,389m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d47.086846!4d-122.566838?entry=ttu")
                break
            case "LOT 5":
                window.open("https://www.google.com/maps/place/47%C2%B005'26.0%22N+122%C2%B034'03.6%22W/@47.0905558,-122.5689405,389m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d47.090554!4d-122.567653?entry=ttu")
        }
    }

    return(
        <>
            <div className={"Parking-Container"}>
                <div className={"Parking-Wrapper"}>
                <Reveal>
                    <div className={"Parking-Compound"}>
                        <div className={"Parking-Distance"}>
                            CLOSEST
                            <img className={"Parking-Pictures"} src={compoundParking} alt={'parking'}/>
                        </div>
                        <div className={"Parking-Compound-Content"}>
                            <div className={"Parking-Line"}>
                                <img className={"Parking-Pin"} src={mapPin} alt={'pin'}/>
                                <div className={"Parking-Text1"}
                                     onClick={() => redirectToMap("CMPD PARKING")}
                                     style={{
                                         cursor:'pointer'
                                     }}
                                >HANDICAP, GOLD STAR, DISTINGUISHED VISITOR PARKING
                                </div>
                            </div>
                            <div className={"Parking-Line"}>
                                <img className={"Parking-Pin"} src={mapPin} alt={'pin'}/>
                                <div className={"Parking-Text"}
                                     onClick={() => redirectToMap("LOT 1")}
                                     style={{
                                         cursor:'pointer'
                                     }}>LOT 1 PARKING
                                </div>
                            </div>
                            <div className={"Parking-Line"}>
                                <img className={"Parking-Pin"} src={mapPin} alt={'pin'}/>
                                <div className={"Parking-Text"}
                                     onClick={() => redirectToMap("LOT 2")}
                                     style={{
                                         cursor:'pointer'
                                     }}>LOT 2 PARKING
                                </div>
                            </div>
                        </div>
                    </div>
                </Reveal>
                </div>
                <div className={"Parking-Wrapper"}>
                    <Reveal>
                        <div className={"Parking-Overflow"}>
                            <div className={"Parking-Distance"}>
                                FARTHEST
                                <img className={"Parking-Pictures"} src={overFlowParking} alt={'parking'}/>
                            </div>
                            <div className={'Parking-Overflow-Content'}>
                                <div className={"Parking-Line"}>
                                    <img className={"Parking-Pin"} src={mapPin} alt={'pin'}/>
                                    <div className={"Parking-Text"}
                                         onClick={() => redirectToMap("LOT 3")}
                                         style={{
                                             cursor:'pointer'
                                         }}>LOT 3 PARKING
                                    </div>
                                </div>
                                <div className={"Parking-Line"}>
                                    <img className={"Parking-Pin"} src={mapPin} alt={'pin'}/>
                                    <div className={"Parking-Text"}
                                         onClick={() => redirectToMap("LOT 4")}
                                         style={{
                                             cursor:'pointer'
                                         }}>LOT 4 PARKING
                                    </div>
                                </div>
                                <div className={"Parking-Line"}>
                                    <img className={"Parking-Pin"} src={mapPin} alt={'pin'}/>
                                    <div className={"Parking-Text"}
                                         onClick={() => redirectToMap("LOT 5")}
                                         style={{
                                             cursor:'pointer'
                                         }}>LOT 5 PARKING</div>
                                </div>
                            </div>
                        </div>
                    </Reveal>
                </div>
            </div>
        </>

    )
}



export default Parking