import React, {useEffect, useState} from "react"
import EventTimeline from "./Pictures/TimeLinePoster.webp"
import EventTimelineMobile from "./Pictures/TimeLineMobile.webp"
import WashDUI from './Pictures/WashingtonDUI.webp'
import GolfFlier from './Pictures/GolfFlier.webp'
import GolfBall from "./Pictures/Golf.webp"
import ARSOA from './Pictures/ARSOARide.webp'
import ACOQR from './Pictures/ACOQR.webp'
import BCOQR from './Pictures/BCoQR.webp'
import CCOQR from './Pictures/CCoQR.webp'
import ECOQR from './Pictures/ECoQR.webp'
import DCoQR from './Pictures/DCoQR.webp'
import Regiment from './Pictures/RegimentSticker.webp'
import "./AllTheCSS/Timeline.css"
import Reveal from "./Reveal";

const Timeline = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(false)

    const handleClick = (num:number) => {
        switch(num) {
            case 1:
                window.open("https://maps.app.goo.gl/H3QLKkDMyU23p8c26")
                break
            case 2:
                window.open( "https://maps.app.goo.gl/RrLJLEydaoY37joc9")
                break
            case 3:
                window.open( "https://maps.app.goo.gl/JEqepHWaMi8wFMW57")
                break
            case 4:
                window.open( "https://maps.app.goo.gl/7eefecE9UDCwohRj6")
                break
            case 5:
                window.open( "https://maps.app.goo.gl/VSVGyipCu8zTcTjv7")
                break
            case 6:
                window.open( "https://maps.app.goo.gl/wtUu2HzFQ6rPf8Zp8") //dv parking//
                break
            case 7:
                window.open( "https://maps.app.goo.gl/arSNx8UK5SeaTBTs8") //Lot 1//
                break
            case 8:
                window.open( "https://maps.app.goo.gl/gRY27DzmwNCzyBYR8") //Lot 2//
                break
            case 9:
                window.open( "https://maps.app.goo.gl/PpCG5MgjVStHXhYR6")//Lot 3//
                break
            case 10:
                window.open( "https://maps.app.goo.gl/xdSA4aQ82yeuaf716") //Lot 4//
                break
            case 11:
                window.open( "https://maps.app.goo.gl/ws4vGvgJv6kcpPx8A") //Lot 5//
                break
            case 12:
                window.open("https://docs.google.com/forms/d/e/1FAIpQLSdc3abP8Xf8d-4_eAA7Kqb_-f2Hdc0Jf0NLMVqOSXGXUWYsbw/viewform")
                break
            case 13:
                window.open("https://www.mcmenamins.com/elks-temple/mcmenamins-pub-at-elks-temple")
                break
            case 14:
                window.open("https://www.7seasbrewing.com/")
                break

            case 15:
                window.open("https://www.dystopianstate.com/?utm_source=google&utm_medium=wix_google_business_profile&utm_campaign=13153364931624358029")
                break
            case 16:
                window.open("https://narrowsbrewing.com/")
                break
            case 17:
                window.open("https://lewisarmymuseum.com/")
                break
            case 18:
                window.open("https://checkout.square.site/merchant/MLHRP366EGA7X/checkout/A32TKY27WK3J3FHDHZ7RDEPF")
                break
            case 19:
                window.open( "https://www.google.com/maps/place/Evergreen+Chapel/@47.0815605,-122.5708527,698m/data=!3m1!1e3!4m6!3m5!1s0x549105ec96a43ecb:0x71f00e345aa2501d!8m2!3d47.0816737!4d-122.570808!16s%2Fg%2F11c1pgx66y?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D") //Evergreen //
                break
            case 20:
                window.open( "https://maps.app.goo.gl/vWXu8f2Ru5tZARSi7") //Range 7//
                break



        }
    }


    useEffect(() => {
        setIsSmallScreen(window.innerWidth <= 900)
        window.scrollTo({top: 0, behavior: 'auto'});
        const handleResize = () => {
            const newWindowWidth = window.innerWidth;
            if (newWindowWidth <= 900) {
                setIsSmallScreen(true);
            } else {
                setIsSmallScreen(false)
            }
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    },[])


    return(

                <div className={'Timeline-Container'}>
                    <div className={'Timeline-header-container'}>
                        <h1> Please Scroll down for more Information (Golf, Company Socials, etc.) </h1>
                    </div>

                    <img className={'Wash-Dui'}src={WashDUI}/>
                    <img className={'Regiment-Background-Pic'} src={Regiment}/>
                    <div className={"QRCode-container"}>
                        <Reveal>
                            <>
                                <img className={'Timeline-Pic'} src={isSmallScreen ? EventTimelineMobile : EventTimeline} alt={'TIMELINE'}/>
                                <div onClick={() => handleClick(1)} className={"QRoverlay QR1"}/>
                                <div onClick={() => handleClick(2)} className={"QRoverlay QR2"}/>
                                <div onClick={() => handleClick(3)} className={"QRoverlay QR3"}/>
                                <div onClick={() => handleClick(4)} className={"QRoverlay QR4"}/>
                                <div onClick={() => handleClick(5)} className={"QRoverlay QR5"}/>
                                <div onClick={() => handleClick(19)} className={"QRoverlay2 QR6"}/>
                                <div onClick={() => handleClick(20)} className={"QRoverlay2 QR7"}/>
                                <div onClick={() => handleClick(6)} className={"QRoverlay2 QR8"}/>
                                <div onClick={() => handleClick(7)} className={"QRoverlay2 QR9"}/>
                                <div onClick={() => handleClick(8)} className={"QRoverlay2 QR10"}/>
                                <div onClick={() => handleClick(9)} className={"QRoverlay2 QR11"}/>
                                <div onClick={() => handleClick(10)} className={"QRoverlay2 QR12"}/>
                                <div onClick={() => handleClick(11)} className={"QRoverlay2 QR13"}/>
                                </>
                        </Reveal>
                    </div>
                    <div className={'Golf-Event-Container'}>
                        <section className={'Left-Side-Pic'}>
                            <img src={GolfFlier}/>
                        </section>
                        <section className={'Right-Side-Content'}>
                            <p> Thursday 03 OCT</p>
                            <p style={{fontSize:'12px', lineHeight:'16px', marginBlockStart:'0', color:'#1C222E'}}> 0800-UTC </p>
                            <h2> GOLF TOURNAMENT</h2>
                            <ul style={{marginBottom:'2rem'}} className={'Timeline-List-Styling'}>
                                <li>Check in opens at 0800 - Shotgun start at 0900 </li>
                                <li>Eagle's Pride Golf Course: 1529 Mounts Road SW, DuPont, WA 98327 </li>
                                <li>All greens fees and food costs are courtesy of the Point du Hoc Foundation, alcohol is available for purchase </li>
                                <li>Luncheon to follow </li>
                                <li>Spots are expected to fill up quick. RSVP now! </li>
                            </ul>
                            <p>**Each foursome will consist of one current Ranger and 3 Alumni** </p>
                            <div className={'Timeline-Button-Container'}>
                                <div className={"Timeline-Golf-RSVP"} onClick={() => handleClick(12)}><img style={{height:"30%", marginRight:"2%"}} src={GolfBall} alt={"GolfBall"}/>RSVP</div>
                            </div>
                            <p className={'Contact-text'}></p>
                        </section>
                    </div>
                    <div className={'Company-Event-Container'} style={{marginBottom:'8rem'}}>
                        <section className={'Left-Side-Company'}>
                            <p> Thursday 03 OCT</p>
                            <p style={{fontSize:'12px', lineHeight:'16px', marginBlockStart:'0', color:'#1C222E'}}> 1300-UTC </p>
                            <h2> COMPANY HOSTED SOCIALS </h2>
                            <div className={'Company-QR-Container'}>
                                <div className={'Company-QR-Block'}>
                                    <h3>A CO</h3>
                                    <img src={ACOQR}/>
                                    <h4 onClick={()=> handleClick(13)}>McMenamins Elk Temple </h4>
                                </div>
                                <div className={'Company-QR-Block'}>
                                    <h3>B CO </h3>
                                    <img src={BCOQR}/>
                                    <div style={{height:'36px'}}>
                                        <h4 style={{height:'initial', paddingBottom:'.4rem'}}onClick={()=> handleClick(14)}>7 Seas Brewing Company </h4>
                                        <h5 onClick={()=> handleClick(18)}>FRG Blankets</h5>
                                    </div>

                                </div>
                                <div className={'Company-QR-Block'}>
                                    <h3>C CO </h3>
                                    <img src={CCOQR}/>
                                    <h4 onClick={()=> handleClick(15)}>Dystopian State Brewing</h4>
                                </div>
                                <div className={'Company-QR-Block'}>
                                    <h3>D CO</h3>
                                    <img src={DCoQR}/>
                                    <h4 onClick={()=> handleClick(17)}>JBLM Museum</h4>
                                </div>
                                <div className={'Company-QR-Block'}>
                                    <h3>E CO & HHC </h3>
                                    <img src={ECOQR}/>
                                    <h4 onClick={()=> handleClick(16)}>Narrows Brewing</h4>
                                </div>

                            </div>
                        </section>
                        <section className={'Right-Side-Company'}>
                            <img style={{width:'43rem'}} src={ARSOA}/>
                        </section>
                    </div>
                </div>
    )
}

export default Timeline