import React, {useEffect, useState} from "react";
import RangerScroll from "./Pictures/RangerScroll.webp";
import './AllTheCSS/Home.css';
import homeBackground from './Pictures/HomeBackground.webp'
import littleBird from './Pictures/LittleBird.webp'
import beachRangers from './Pictures/RangersOnBeach.webp'
import guyShooting from './Pictures/GuyShooting.webp'
import tugOWar from './Pictures/TugOWar.webp'
import CustomButton from "./CustomButton";
import Reveal from "./Reveal";
import CountDown from "./CountDown";

interface props {
    setPageSelector: React.Dispatch<React.SetStateAction<number>>
}

const Home = ({setPageSelector}: props) => {
    const [isSmallScreen, setIsSmallScreen] = useState(false)

    useEffect(() => {
        setIsSmallScreen(window.innerWidth < 1200)
        window.scrollTo({top: 0, behavior: 'auto'});
        const handleResize = () => {
            const newWindowWidth = window.innerWidth;
            if (newWindowWidth < 1200) {
                setIsSmallScreen(true);
            } else {
                setIsSmallScreen(false)
            }
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    },[])

    const handleClick = () => {
        setPageSelector(1);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return(
        <>
            <div className={'Home-Container'} style={{backgroundImage:`url(${homeBackground})`}}>
                <div className={'rangerScroll-container'}>
                    <img className={'home-rangerScroll'} src={RangerScroll} alt={'rangerScroll'}/>
                </div>
                <div className={'Home-TextContainer'}>
                    <Reveal>
                        <div className={'home-reveal-container'}>
                            <div>
                                <p className={'Home-Text-A'}>OVER 100 PATHS TO SERVE</p>
                                <p className={'Home-Text-B'}>WHICH WILL YOU TAKE?</p>
                            </div>
                            <CustomButton onClick={() => setPageSelector(2)}>
                                EXPLORE CAREER PATHS
                            </CustomButton>
                        </div>
                    </Reveal>
                </div>
            </div>
            <div className={'FurtherFaster'} style={{backgroundImage:`url(${littleBird})`}}>
                <span>-FURTHER, FASTER, AND FIGHT HARDER-</span>
            </div>
            <div className={'BecomeARanger-Container'}>
                {isSmallScreen ?
                    <div className={'BecomeARanger-Content-A'}>
                        <div className={'BecomeARanger-Text-Container-A'}>
                            <p style={{textAlign:'center', fontSize:'32px', fontWeight:'bold'}}>JOIN US<br/>BECOME AN ARMY RANGER</p>
                            <img className={'homePic'} src={tugOWar} alt={'tugOWar'}/>
                            <div style={{width:'100%'}}>
                                <p>The 75th Ranger Regiment is the U.S. Army’s premier large-scale Special Operations Force and it is made up of some of the most elite soldiers in the world.</p>
                                <p>Being a Ranger is no easy task, requires a big commitment and is not for the faint of heart. </p>
                            </div>
                        </div>
                    </div>
                    :
                    <div className={'BecomeARanger-Content-A'}>
                        <div className={'BecomeARanger-Text-Container-A'}>
                            <p style={{textAlign:'center', fontSize:'32px', fontWeight:'bold'}}>JOIN US<br/>BECOME AN ARMY RANGER</p>
                            <div style={{width:'85%'}}>
                                <p>The 75th Ranger Regiment is the U.S. Army’s premier large-scale Special Operations Force and it is made up of some of the most elite soldiers in the world.</p>
                                <p>Being a Ranger is no easy task, requires a big commitment and is not for the faint of heart. </p>
                            </div>

                        </div>
                        <img className={'homePic'} src={tugOWar} alt={'tugOWar'}/>
                    </div>
                }

                <div className={'BecomeARanger-Content-B'}>
                    <img className={'homePic'} src={guyShooting} alt={'guyShooting'}/>
                    <div className={'BecomeARanger-Text-B'}>
                        <p style={{fontWeight:'bold', color:'white', textAlign:'center', fontSize:'32px', margin:'0rem'}}>EXPLORE YOUR POTENTIAL</p>
                        <ul className={'Home-List-Container'}>
                            <li className={'Home-List'}>Surround yourself with driven like-minded individuals.</li>
                            <li className={'Home-List'}>Push yourself to your limits.</li>
                            <li className={'Home-List'}>Gain life long friends.</li>
                        </ul>
                        <div style={{display:'flex', justifyContent:`${window.innerWidth < 1200 ? 'center' : 'flex-end'}`, width:'90%', height:`${window.innerWidth <= 1200 ? '4rem' : ''}`}}>
                            <CustomButton onClick={() => handleClick()}>Learn More</CustomButton>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'DoYouHave'} style={{backgroundImage:`url(${beachRangers})`}}>
                -DO YOU HAVE WHAT IT TAKES?-
            </div>
        </>
    )
}
export default Home


