import CustomButton from "./CustomButton";
import React from "react";
import diamond from "./Pictures/TwoDiamondForEventsPage.webp"
import internal from "stream";
interface props {
    //children: React.ReactNode
    eventId: string
    eventDayName: string
    eventDay: string
    eventMonth: string
    eventTitle: string
    eventDescription: string
    eventLocation: string
    eventTimeframe: string
    eventPage: number
    eventClick: any
}

export let titleArr: string[] = [];

// const handleClick = (num: number) => {
//     setNumber(num)
//     const updatedShowDiv = [...showDiv]
//     updatedShowDiv[num] = !updatedShowDiv[num]
//     setShowDiv(updatedShowDiv)
// }
//
// const handleClick = () => {
//     setPageSelector(5);
//     window.scrollTo({ top: 0, behavior: 'smooth' });
// }

const CustomEvent = ({eventId, eventDayName, eventDay, eventMonth, eventTitle, eventDescription, eventLocation, eventTimeframe, eventPage, eventClick}: props ) => {

    if (!titleArr.includes(eventTitle)) {
        titleArr.push(eventTitle)
    };
    //console.log(titleArr)
    // if the search bar has an input, display this
    // this = event.name.contains(input) return <event>
if (window.innerWidth < 1200) {
    //console.log("mobile screen")
    return (
        <div id={eventId} className={"Events-EventTile"}>
            <div className={"Events-Wrapper"}>
            <div className={"Events-EventTileDate"}>
                <div style={{fontSize: 'medium'}}>{eventDayName}</div>
                <div style={{fontSize: 'xxx-large'}}>{eventDay}</div>
                <div>{eventMonth}</div>
            </div>

            <div className={"Events-EventTileDescription"}>
                <div className={"Events-EventTileDescription-Header"}>
                    {eventTitle}
                </div>
                <div className={"Events-EventTileDescription-Body"}>
                    {eventDescription}
                </div>
                <div className={"Events-EventTileDescription-Location"}>
                    {eventLocation}
                </div>
                <div className={"Events-EventTileDescription-Time"}>
                    {eventTimeframe}
                </div>
            </div>
            </div>
                <div className={'Events-Explore'}>
                    <img className={'Events-Diamond'} src={diamond} />
                    <div className={"event-customButton-container"}>
                        <CustomButton onClick={eventClick}>EXPLORE</CustomButton>
                    </div>
                </div>

        </div>
    )

} else {
    //console.log("computer screen")
    return (
        <div id={eventId} className={"Events-EventTile"}>
            <div className={"Events-EventTileDate"}>
                <div style={{fontSize: 'medium'}}>{eventDayName}</div>
                <div style={{fontSize: 'xxx-large'}}>{eventDay}</div>
                <div>{eventMonth}</div>
            </div>

            <div className={"Events-EventTileDescription"}>
                <div className={"Events-EventTileDescription-Header"}>
                    {eventTitle}
                </div>
                <div className={"Events-EventTileDescription-Body"}>
                    {eventDescription}
                </div>
                <div className={"Events-EventTileDescription-Location"}>
                    {eventLocation}
                </div>
                <div className={"Events-EventTileDescription-Time"}>
                    {eventTimeframe}
                </div>
            </div>
            <div className={'Events-Explore'}>
                <CustomButton onClick={eventClick}>EXPLORE</CustomButton>
            </div>
        </div>
    )
}
}
export default CustomEvent