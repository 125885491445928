import React, {useEffect, useState} from "react";
import ConnectBackground from './Pictures/ConnectBackground.webp'
import './AllTheCSS/Connect.css'
import RangerScrollTab from './Pictures/RangerScrollTab.webp'
import CustomButton from "./CustomButton";
import RadioButton from'./Pictures/Icon_radio 2.webp'
import SelectedRadioButton from './Pictures/Icon_radio_1.webp'
import submitCheckMark from './Pictures/completedSubmitCheckAmrk.webp'

interface props {
    name: string
    setPageSelector: React.Dispatch<React.SetStateAction<number>>
}


const Connect = ({name, setPageSelector}: props) => {
    const [card, setCard] = useState(0)
    const [selectedYes, setSelectedYes] = useState(false)
    const [selectedNo, setSelectedNo] = useState(false)
    const [internetButton, setInternetButton] = useState(false)
    const [womButton, setWomButton] = useState(false)
    const [refButton, setRefButton] = useState(false)
    const [unitButton, setUnitButton] = useState(false)
    const [otherButton, setOtherButton] = useState(false)
    const [disable, setDisable] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [activeDuty, setActiveDuty] = useState(false)

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'auto'});
    },[])


    /**formDataInformation contains the info for Information Request*/
    const [formDataInformation, setFormDataInformation] = useState({
        names: '',
        phone: '',
        email: '',
        message: ''
    })
    const buttonsSelected = {
        Internet: internetButton, WoM: womButton, Referral: refButton, Unit: unitButton, Other: otherButton
    }
    const selectedButtonsFiltered = Object.keys(buttonsSelected).filter(key => !buttonsSelected);
    const finalSlected = selectedButtonsFiltered.join(', ')

    /**formData contains the Connect With A Recruiter*/
    const [formData, setFormData] = useState({
        active: false,
        fullName: '',
        emails: '',
        phones: '',
        branch: '',
        dutyStation: '',
        rank: '',
        mos: '',
        hdyhau: finalSlected,
        reference: ''
    })

    const updateFormData = (newValue:boolean) => {
        setFormData(prevState => ({
            ...prevState,
            active:newValue
        }))
    }

    const handleSelectYes = () => {
        setSelectedYes(!selectedYes);
        setSelectedNo(false);
        setActiveDuty(true)
        console.log("In handleSelect YES/ active duty " + activeDuty )
    };
    const handleSelectNo = () => {
        setSelectedNo(!selectedNo);
        setSelectedYes(false);
        setActiveDuty(false)
        console.log("In handleSelect NO/ active duty " + activeDuty )
    };


    const handleChangeInformation = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormDataInformation({
            ...formDataInformation,
            [name]: value
        });
    }
    const fillForm = () => {
        const { names, phone, email, message } = formDataInformation
        console.log( "IN FILL FORM" + "Name: " + {names}.names + ", email: " + {email}.email + ", phone: " + {phone}.phone + ", message: " + {message}.message)
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
    }
    const fillFormInfo = () => {
        const { active, fullName, emails, phones, branch, dutyStation, rank, mos, hdyhau, reference } = formData
        console.log( "IN FILL FORM INFO" + "1 " + {active}.active ,"2 " +  {fullName}.fullName ,"3 " + {emails}.emails,"4 " +  {phones}.phones,
            "5 " + {branch}.branch,"6 " + {dutyStation}.dutyStation ,"7 " + {rank}.rank,"8 " + {mos}.mos,"9 " + {hdyhau}.hdyhau,"10 " + {reference}.reference )
    }

    const handleChangeRef = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({...formData, reference: event.target.value})
    }

    const sendEmail = async () => {
        const data = {
            userName: formData.fullName || formDataInformation.names,
            userPhone: formData.phones || formDataInformation.phone,
            userEmail: formData.emails || formDataInformation.email,
            userMessage: formDataInformation.message,
            userActiveDuty: formData.active,
            userBranch: formData.branch,
            userDutyStation: formData.dutyStation,
            userRank: formData.rank,
            userMos: formData.mos,
            userHdyhau: formData.hdyhau,
            userReferral: formData.reference
        };
        try {
            const response = await fetch('https://localhost:3000/sendEmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                console.log(JSON.stringify(data) + " Email sent successfully");
                return true;
            } else {
                console.log("fail" + JSON.stringify(data));
                return false;
            }
        } catch (error) {
            console.log(JSON.stringify(data));
            return false;
        }
    };

    const handleSend = async () => {
        const success = await sendEmail()
        if(success){
            console.log("success handle send")
        } else {
            console.log("fail")
        }
    }




    if(name === "question") {
        return(
        <>
            <div className={'connect-full-container'} style={{backgroundImage: `url(${ConnectBackground})`}}>
                <div className={'background-connect'}>
                    <div className={'connect-header'}>INFORMATION REQUEST</div>
                    <div className={'connect-main-div-container'}>
                        <div>
                            Thank you for your interest in the 75th. For general inquiries,
                            please see our <span style={{color:'#C72727', textDecoration:'underline', cursor:'pointer'}} onClick={() => setPageSelector(4)}>Frequently Asked Questions</span> If you would like to contact us, complete and submit
                            the form below and we will get in touch with you.
                        </div>
                    </div>
                    {/*{!submitted ?*/}
                        <div className={'connect-main-model'} style={{backgroundColor: 'rgba(30, 34, 44, 1)'}}>
                            <div className={'connect-main-top-div'}><img src={RangerScrollTab} alt={'scrolltab'} className={'scrollTab'}/></div>
                            <div className={'connect-main-model-input-container'}>
                                <div className={'connect-main-container-new'}>
                                    <div className={'connect-main-model-top-new'}>
                                        If interested in applying to the 75th Ranger Regiment,
                                        please contact us at the following phone number or
                                        appropriate email to request an application.
                                    </div>
                                    <div className={'connect-main-model-div-new'}>
                                        <div className={'connect-main-model-new'}>
                                            RECRUITING HOTLINE: (706)545-5124
                                        </div>
                                        <div className={'connect-main-model-new'}>
                                            ENLISTED: 75RECRUIT@SOCOM.MIL
                                        </div>
                                        <div className={'connect-main-model-new'}>
                                            OFFICER/WARRANT: 75OFFICERRECRUIT@SOCOM.MIL
                                        </div>
                                    </div>
                                </div>


                                {/*<div className={'connect-main-model-div'}>NAME</div>*/}
                                {/*    <input id={'names'} name="names" value={formDataInformation.names} className={'connect-First-Three-Inputs'}*/}
                                {/*       placeholder={"Enter Your Name"}*/}
                                {/*           autoComplete={"off"}*/}
                                {/*       onChange={handleChangeInformation}*/}
                                {/*    />*/}
                                {/*<div className={'connect-main-model-div'}>EMAIL</div>*/}
                                {/*<input id={'email'} name="email" value={formDataInformation.email} className={'connect-First-Three-Inputs'}*/}
                                {/*       placeholder={"Your Email"}*/}
                                {/*       autoComplete={"off"}*/}
                                {/*       onChange={handleChangeInformation}*/}
                                {/*/>*/}
                                {/*<div className={'connect-main-model-div'}>PHONE</div>*/}
                                {/*<input id={'phone'} name="phone" value={formDataInformation.phone} className={'connect-First-Three-Inputs'}*/}
                                {/*       placeholder={"Your Phone Number"}*/}
                                {/*       autoComplete={"off"}*/}
                                {/*       onChange={handleChangeInformation}*/}
                                {/*/>*/}
                                {/*<div className={'connect-main-model-div'}>MESSAGE</div>*/}
                                {/*<div className={'connect-main-model-message-container'}>*/}
                                {/*    <textarea*/}
                                {/*        className={'connect-Email-Input'}*/}
                                {/*        placeholder={'Insert Your Message'}*/}
                                {/*        id={'message'}*/}
                                {/*        name="message" value={formDataInformation.message}*/}
                                {/*        onChange={ handleChangeInformation }*/}
                                {/*        rows={8}*/}
                                {/*        cols={50}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                {/*<div className={'connect-submit-button'}*/}
                                {/*onClick={fillForm}*/}
                                {/*>*/}
                                {/*    <CustomButton*/}
                                {/*        onClick={async () => {*/}
                                {/*            if (formDataInformation.names === ''*/}
                                {/*                || formDataInformation.phone === ''*/}
                                {/*                || formDataInformation.email === ''*/}
                                {/*                || formDataInformation.message === '') {*/}
                                {/*                setSubmitted(false)*/}
                                {/*            } else {*/}
                                {/*                setSubmitted(true);*/}
                                {/*                await handleSend();*/}
                                {/*            }*/}
                                {/*        }}*/}
                                {/*    >SUBMIT</CustomButton>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    {/*    :*/}
                    {/*    <div style={{ display:'flex', justifyContent:'center', width:'100%'}}>*/}
                    {/*        <div className={'connect-question-main-model'} style={{backgroundColor: 'rgba(30, 34, 44, 1)'}}>*/}
                    {/*            <div className={'scrollTab-question-container'}><img src={RangerScrollTab} alt={'scrolltab'} className={'scrollTab'}/></div>*/}
                    {/*            <div className={'connect-submit-container'}>*/}
                    {/*                <img src={submitCheckMark} alt={'submit checkmark'}/>*/}
                    {/*                <div className={'connect-submit-spacing-1'}>THANK YOU!</div>*/}
                    {/*                <div className={'connect-submit-spacing'}>Your submission has been sent.</div>*/}
                    {/*                <div className={'connect-submit-spacing'}>Our recruiters will get back to you ASAP.</div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*}*/}
                </div>
            </div>
        </>
        )
    } else {
        return(
            <>
                <div className={'connect-full-container'} style={{backgroundImage: `url(${ConnectBackground})`}}>
                    <div className={'background-connect'}>
                        <div className={'connect-header'}>CONTACT A RECRUITER</div>
                        <div className={'connect-main-div-container'}>Thank you for your interest in becoming an Army Ranger. Please complete and submit the application to be contacted by a recruiter.</div>
                        <div className={'connect-main-model'} style={{backgroundColor: 'rgba(30, 34, 44, 1)'}}>
                            <div className={'connect-main-top-div'}><img src={RangerScrollTab} alt={'scrolltab'} className={'scrollTab'}/></div>
                            <div className={'connect-main-model-input-container'}>
                                <div className={'connect-main-container-new'}>
                                    <div className={'connect-main-model-top-new'}>
                                        If interested in applying to the 75th Ranger Regiment,
                                        please contact us at the following phone number or
                                        appropriate email to request an application.
                                    </div>
                                    <div className={'connect-main-model-div-new'}>
                                        <div className={'connect-main-model-new'}>
                                            RECRUITING HOTLINE: (706)545-5124
                                        </div>
                                        <div className={'connect-main-model-new'}>
                                            ENLISTED: 75RECRUIT@SOCOM.MIL
                                        </div>
                                        <div className={'connect-main-model-new'}>
                                            OFFICER/WARRANT: 75OFFICERRECRUIT@SOCOM.MIL
                                        </div>
                                    </div>
                                </div>


                                {/*<div className={'connect-main-model-div'}>NAME</div>*/}
                                {/*    <input id={'names'} name="names" value={formDataInformation.names} className={'connect-First-Three-Inputs'}*/}
                                {/*       placeholder={"Enter Your Name"}*/}
                                {/*           autoComplete={"off"}*/}
                                {/*       onChange={handleChangeInformation}*/}
                                {/*    />*/}
                                {/*<div className={'connect-main-model-div'}>EMAIL</div>*/}
                                {/*<input id={'email'} name="email" value={formDataInformation.email} className={'connect-First-Three-Inputs'}*/}
                                {/*       placeholder={"Your Email"}*/}
                                {/*       autoComplete={"off"}*/}
                                {/*       onChange={handleChangeInformation}*/}
                                {/*/>*/}
                                {/*<div className={'connect-main-model-div'}>PHONE</div>*/}
                                {/*<input id={'phone'} name="phone" value={formDataInformation.phone} className={'connect-First-Three-Inputs'}*/}
                                {/*       placeholder={"Your Phone Number"}*/}
                                {/*       autoComplete={"off"}*/}
                                {/*       onChange={handleChangeInformation}*/}
                                {/*/>*/}
                                {/*<div className={'connect-main-model-div'}>MESSAGE</div>*/}
                                {/*<div className={'connect-main-model-message-container'}>*/}
                                {/*    <textarea*/}
                                {/*        className={'connect-Email-Input'}*/}
                                {/*        placeholder={'Insert Your Message'}*/}
                                {/*        id={'message'}*/}
                                {/*        name="message" value={formDataInformation.message}*/}
                                {/*        onChange={ handleChangeInformation }*/}
                                {/*        rows={8}*/}
                                {/*        cols={50}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                {/*<div className={'connect-submit-button'}*/}
                                {/*onClick={fillForm}*/}
                                {/*>*/}
                                {/*    <CustomButton*/}
                                {/*        onClick={async () => {*/}
                                {/*            if (formDataInformation.names === ''*/}
                                {/*                || formDataInformation.phone === ''*/}
                                {/*                || formDataInformation.email === ''*/}
                                {/*                || formDataInformation.message === '') {*/}
                                {/*                setSubmitted(false)*/}
                                {/*            } else {*/}
                                {/*                setSubmitted(true);*/}
                                {/*                await handleSend();*/}
                                {/*            }*/}
                                {/*        }}*/}
                                {/*    >SUBMIT</CustomButton>*/}
                                {/*</div>*/}
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }


}
export default Connect
