import React, {useEffect, useRef, useState} from "react";
import './AllTheCSS/CareerPath.css'
import enlistedMosList from './Pictures/EnlistedMOSList.webp'
import officerMosList from './Pictures/WarrantMOSList.webp'
import warOfficers from './Pictures/image 116.webp'
import enlisted from './Pictures/image 117.webp'
import careerBackGround from './Pictures/CareerPathBackground.webp'
import officer from './Pictures/image 115.webp'
import assessmentPic from './Pictures/assessmentBackground.webp'
import assessmentDropDownPic from './Pictures/assessmentDropDownPic.webp'
import enlistedBackground from './Pictures/enlistedBackground.webp'
import warrantBackground from './Pictures/WarrantBackground.webp'
import officerBackground from './Pictures/officerBackground.webp'
import enlistedDropDown from './Pictures/RangerEnlistedDropDown.webp'
import officerDropDown from './Pictures/RangerOfficerDropDown.webp'
import warrantDropDown from './Pictures/RangerWarrantDropDown.webp'
import CustomButton from "./CustomButton";
import {redirectToExternalSite} from "./Helpers";
import Reveal from "./Reveal";

interface props {
    name: string
    setName: React.Dispatch<React.SetStateAction<string>>
}

const CareerPath = ({name, setName}:props) => {
    const [showDiv, setShowDiv] = useState([false, false, false, false, false])
    const [number, setNumber] = useState(20)
    const topOfRasp = useRef<HTMLDivElement>(null)
    const topOfOfficer = useRef<HTMLDivElement>(null)
    const topOfWarrant = useRef<HTMLDivElement>(null)
    const topOfEnlisted = useRef<HTMLDivElement>(null)
    const dropDownOne = useRef<HTMLDivElement>(null)
    const dropDownTwo = useRef<HTMLDivElement>(null)
    const dropDownThree = useRef<HTMLDivElement>(null)
    const dropDownFour = useRef<HTMLDivElement>(null)
    const dropDownFive = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if ((name === 'officer') && topOfOfficer.current) {
                topOfOfficer.current.scrollIntoView({behavior:'smooth'})
            } else if (name === "rasp" && topOfRasp.current) {
                topOfRasp.current.scrollIntoView({behavior:'smooth'})
            } else if (name === "warrant" && topOfWarrant.current) {
                topOfWarrant.current.scrollIntoView({behavior:'smooth'})
            } else if (name === "enlisted" && topOfEnlisted.current) {
                topOfEnlisted.current.scrollIntoView({behavior:'smooth'})
            } else if (name === 'whichPath'){
                window.scrollTo({top: 0, behavior: 'smooth'});
            }
        }, 100)
        return () => {
            clearTimeout(timeoutId)
        }
    },[name])

    useEffect(() => {
        if (dropDownOne.current && showDiv[0] && number === 0) {
            dropDownOne.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
        }  else if (dropDownTwo.current && showDiv[1] && number === 1) {
            dropDownTwo.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        } else if (dropDownThree.current && showDiv[2] && number === 2) {
            dropDownThree.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        } else if (dropDownFour.current && showDiv[3] && number === 3) {
            dropDownFour.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        } else if (dropDownFive.current && showDiv[4] && number === 4) {
            dropDownFive.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        }
    },[showDiv])

    const handleClick = (num: number) => {
        setNumber(num)
        const updatedShowDiv = [...showDiv]
        updatedShowDiv[num] = !updatedShowDiv[num]
        setShowDiv(updatedShowDiv)
    }

    return(
        <>
            <div  className={'careerPath-full-container'} style={{backgroundImage:`url(${careerBackGround})`, backgroundSize: "cover", backgroundPosition:'15%'}}>
                <div className={'careerPath-header'}>WHICH PATH WILL YOU TAKE?</div>
                <div className={'careerPath-main-div-container'}>
                    <Reveal>
                        <div className={'careerPath-reveal-container'}>
                            <div className={'careerPath-main-div'}>
                                <span style={{marginBottom:'1.5rem'}}>Everyone is a Ranger First</span>
                                <span className={'careerPath-main-div-p'}>Regardless of career field, everyone goes through RASP (Ranger Assessment and Selection Program).
                            Everyone earns their tan beret and Ranger scroll.
                            Our all-volunteer force is comprised of more than 60 enlisted MOS’s and over 40 officer/warrant officer positions.
                                <br/><span style={{fontSize:'24px', fontWeight:'bold', marginTop:'1.5rem'}}>There are more than 100 ways to serve as a Ranger.</span>
                            </span>
                            </div>
                            <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-end'}}>
                                <CustomButton onClick={() => handleClick(0)}>Learn More</CustomButton>
                            </div>
                        </div>
                    </Reveal>

                </div>
            </div>
                {showDiv[0] &&
                <div ref={dropDownOne} style={{backgroundColor: "#1E222C", width:'100%', display:'flex', justifyContent:'space-around', flexDirection:'column', alignItems:'center', color:'white', height:'auto'}}>
                    <div style={{display:'flex', flexDirection:'row', width:`${window.innerWidth < 1200 ? '95%' : '70%'}`, justifyContent:'space-between', marginTop:`${window.innerWidth < 1200 ? '0rem' : '4rem'}`}}>
                        <div className={'careerPath-Second-Container-Picture'}>
                            <img style={{maxWidth:'100%', width:'auto', height:'auto', borderRadius:'15px' }} src={officer} alt={'officer'}/>
                            <div style={{marginTop:'1vw'}}>OFFICER</div>
                        </div>
                        <div className={'careerPath-Second-Container-Picture'}>
                            <img style={{maxWidth:'100%', width:'auto', height:'auto', borderRadius:'15px'}} src={enlisted} alt={'enlisted'}/>
                            <div style={{marginTop:'1vw'}}>WARRANT-OFFICER</div>
                        </div>
                        <div className={'careerPath-Second-Container-Picture'}>
                             <img style={{maxWidth:'100%', width:'auto', height:'auto', borderRadius:'15px'}} src={warOfficers} alt={'warrantOfficer'}/>
                            <div style={{marginTop:'1vw'}}>ENLISTED</div>
                        </div>
                    </div>
                    <div className={'careerPath-Second-Container-requirements-container'}>
                        <div className={'careerPath-Second-Container-requirements-header'}> All Ranger candidates must meet the following requirements:</div>
                        <div className={'careerPath-Second-Container-requirements'}>
                            <div className={'careerPath-Second-Container-requirements-div'}>• Must be a U.S Citizen</div>
                            <div className={'careerPath-Second-Container-requirements-div'}>• Active Duty Army Soldiers with no pending UCMJ action</div>
                            <div className={'careerPath-Second-Container-requirements-div'}>• GT Score of 100 or higher (waiver case by case)</div>
                            <div className={'careerPath-Second-Container-requirements-div'}>• Airborne Qualified or agree to attend airborne training prior to assignment</div>
                            <div className={'careerPath-Second-Container-requirements-div'}>• Eligible to obtain a secret clearance</div>
                            <div className={'careerPath-Second-Container-requirements-div'}>
                                • Pass physical requirements which include the Ranger Fitness Test (41 T-push-ups, 2:35 plank,
                                run 5 miles in 40 minutes or less, 6 pull-ups),
                                Water Assessment, and 12 mile foot-march with a 35lb rucksack and weapon in 3 hours or less.
                            </div>
                            <div style={{textAlign:'center', paddingTop:'1rem'}}> *(National Guard/Reserve Soldiers please call recruiter for specific RASP questions)</div>
                        </div>
                    </div>
                    <div className={'MosChart-Container'}>
                        <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
                            <img style={{width:'80%'}} src={enlistedMosList} alt={'enlisted MOS List'}/>
                        </div>
                        <div style={{width:'100%', display:'flex', justifyContent:'center', marginTop:'2rem'}}>
                            <img style={{width:'80%'}} src={officerMosList} alt={'officer MOS list'}/>
                        </div>
                    </div>
                </div>
                }
            <div ref={topOfRasp} className={'assessmentContainer'} style={{ backgroundImage: `url(${assessmentPic})`,backgroundSize: "cover", backgroundPosition:'center'}}>
                <div className={'assessment-Header'}>
                    THE ASSESSMENT
                </div>
                <div className={'assessment-text-container'}>
                    <Reveal>
                        <div className={'careerPath-reveal-container'}>
                        <div className={'assessment-text'}>
                            <span style={{fontWeight:'bold'}}>The Ranger Assessment and Selection Program</span> (RASP) is a physically and mentally demanding eight-week course held at Fort Moore, Georgia.
                            RASP 1 is for Junior Noncommissioned Officers and Enlisted Soldiers (pay grades E-1 through E-5) RASP 2 for Senior Noncommissioned Officers, Officers and Warrant Officers.
                        </div>
                        <CustomButton onClick={() => handleClick(1)}>Learn More</CustomButton>
                            </div>
                    </Reveal>

                </div>
            </div>
                {showDiv[1] &&
                    <div ref={dropDownTwo} className={'assessmentDropDown-Container'}>
                        <div className={'assessmentDropDown-Content-Container'}>
                            <div style={{display:'flex', justifyContent:'center'}}>
                                <img style={{maxWidth:'90%', width:'auto', height:'auto'}} src={assessmentDropDownPic} alt={'assessmentDropDownPic'}/>
                            </div>
                            <div className={'assessmentDropDown-Text-Container'}>
                                <div className={'assessmentDropDown-Text-Header'} style={{width:'80%'}}>
                                    The <strong>Ranger Assessment and Selection Program</strong> (RASP) has two phases:
                                </div>
                                <ul style={{paddingLeft:'1rem', width:'78%'}}>
                                    <li>
                                        Phase 1
                                        <ul>
                                            <li>
                                                Physical and psychological tests, including a 12-mile ruck march, land navigation exercises, medical first responder tests, and a psychological screening
                                            </li>
                                        </ul>
                                    </li>
                                    <li style={{marginTop:'1rem'}}>
                                        Phase 2
                                        <ul>
                                            <li>
                                                Ranger skills training, including direct-action combat, airfield seizure, personnel recovery, marksmanship, and explosives
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                }
            <div ref={topOfOfficer} className={'assessmentContainer'} style={{ backgroundImage: `url(${officerBackground})`,backgroundSize: "cover", backgroundPosition:'center'}}>
                <div className={'assessment-Header'}>
                    RANGER OFFICER
                </div>
                <div className={'assessment-text-container'}>
                    <Reveal>
                        <div className={'careerPath-reveal-container'}>
                            <div className={'assessment-text'}>
                                <span style={{fontWeight:'bold'}}>The Regiment’s Commissioned Officers</span> bring exceptional leadership and operational expertise to the unit.
                                They possess a variety of experience in conventional and special operations units and have extensive special skills training.
                                Ranger officers are second-time platoon leaders, company commanders and battalion commanders.
                                They take valuable lessons learned in combat and tactics, techniques and procedures with them to their next assignments
                            </div>
                            <CustomButton onClick={() => handleClick(2)}>Learn More</CustomButton>
                        </div>
                    </Reveal>
                </div>
            </div>
                {showDiv[2] &&
                <div ref={dropDownThree} className={'assessmentDropDown-Container'}>
                    <div className={'officerDropDown-Content'}>
                        <div className={'officerDropDown-Text-Container'}>
                            <div className={'officerDropDown-Header'}>
                                Officers interested in an assignment to the Ranger Regiment should send an email to <span style={{color:'#C72727'}}>75OFFICERRECRUIT@SOCOM.MIL</span> and follow the below procedures:
                            </div>
                            <ul>
                                <li>Subject Line of the Email:
                                    <ul>
                                        <li>"Last, First, Rank, Branch YG## - Initial/Return Application"</li>
                                    </ul>
                                </li>
                                <li>Attachments:
                                    <ul>
                                        <li>
                                            STP, all OERs, and AERs; and pre-screening questionnaire
                                        </li>
                                            <a
                                                className={'careerPath-hyperLink'} onClick={()=>{redirectToExternalSite('Pdf')}}> RASP Pre-Screening Questionnaire
                                            </a>

                                    </ul>
                                </li>
                                <li>State in the body of your email the following information:
                                    <ul>
                                        <li>
                                            Current Duty Position and time in that position.
                                        </li>
                                        <li>
                                            Any unique qualifications you have that may distinguish you from your peers.
                                        </li>
                                    </ul>
                                </li>
                                <li>Submit the initial application 3-4 months into your first Key Development Duty position in your current grade and branch</li>
                                <li>Final application must include a minimum of 2 x Letters of Recommendation (Rater and Senior Rater); 4187 signed by 0-6 Commander, PT Card and Pre-Screening Questionnaire</li>
                            </ul>
                        </div>
                        <div style={{display:'flex', justifyContent:'center'}}>
                            <img style={{maxWidth:'90%', width:'auto', height:'auto'}} src={officerDropDown} alt={'officerDropDown'}/>
                        </div>
                    </div>
                </div>
                }
            <div ref={topOfWarrant} className={'assessmentContainer'} style={{ backgroundImage: `url(${warrantBackground})`,backgroundSize: "cover", backgroundPosition:'70%'}}>
                <div className={'assessment-Header'}>
                    RANGER WARRANT OFFICER
                </div>
                <div className={'assessment-text-container'}>
                    <Reveal>
                        <div className={'careerPath-reveal-container'}>
                            <div className={'assessment-text'}>
                                <span style={{fontWeight:'bold'}}>Ranger Warrant Officers</span> provide technical expertise to commanders and staff’s facilitating mutual Army and SOF specific requirements to Rangers.
                                Their experience, knowledge, and training provide crucial links that assist synchronizing key functions and operations in the Ranger Regiment.
                                Ranger Warrants reside at various levels in Logistics, Military Intelligence, Signal, Human Resources, and Fires positions throughout the Regiment.
                            </div>
                            <CustomButton onClick={() => handleClick(3)}>Learn More</CustomButton>
                        </div>
                    </Reveal>
                </div>
            </div>
                {showDiv[3] &&
                <div ref={dropDownFour} className={'assessmentDropDown-Container'}>
                    <div className={'warrantOfficerDropDown-Content'}>
                        <div style={{display:'flex', justifyContent:'center'}}>
                            <img style={{maxWidth:'90%', width:'auto', height:'auto'}} src={warrantDropDown} alt={'warrantDropDown'}/>
                        </div>
                        <div className={'officerDropDown-Text-Container'}>
                            <div>
                                <span style={{fontWeight:'bold'}}>Warrant Officers</span> interested in an assignment to the Ranger Regiment should send an email to <span style={{color:'#C72727'}}>75OFFICERRECRUIT@SOCOM.MIL</span> and follow the below procedures:
                            </div>
                            <ul>
                                <li>Subject Line of the Email:
                                    <ul>
                                        <li>"Last, First, Rank, Branch YG## - Initial/Return Application"</li>
                                    </ul>
                                </li>
                                <li>Attachments:
                                    <ul>
                                        <li>
                                            STP, all OERs, and AERs; and pre-screening questionnaire:
                                            <div>
                                                <a className={'careerPath-hyperLink'} onClick={()=>{redirectToExternalSite('Pdf')}}>RASP Pre-Screening Questionnaire
                                                </a>
                                            </div>

                                        </li>
                                    </ul>
                                </li>
                                <li>State in the body of your email the following information:
                                    <ul>
                                        <li>
                                            Current Duty Position and time in that position.
                                        </li>
                                        <li>
                                            Month and year your Chain of Command supports your attendance to RASP 2
                                        </li>
                                        <li>
                                            Any unique qualifications you have that may distinguish you from your peers.
                                        </li>
                                    </ul>
                                </li>
                                <li>Submit the initial application 3-4 months into your first Key Development Duty position in your current grade and branch</li>
                                <li>Final application must include a minimum of 2 x Letters of Recommendation (Rater and Senior Rater); 4187 signed by 0-6 Commander, PT Card and Pre-Screening Questionnaire</li>
                            </ul>
                        </div>
                    </div>
                </div>
                }
            <div ref={topOfEnlisted} className={'assessmentContainer'} style={{ backgroundImage: `url(${enlistedBackground})`,backgroundSize: "cover", backgroundPosition:'center'}}>
                <div className={'assessment-Header'} style={{textAlign:'center'}}>
                    RANGER ENLISTED
                    <br/>AND<br/>
                    NON-COMMISSIONED OFFICERS
                </div>
                <div className={'assessment-text-container'}>
                    <Reveal>
                        <div className={'careerPath-reveal-container'}>
                            <div className={'assessment-text'}>
                                <span style={{fontWeight:'bold'}}>Ranger NCOs</span> are among the most experienced in the Army. They have multiple combat deployments and intense training experience.
                                Their expertise and leadership are critical in planning and executing combat operations.
                                NCOs also prepare junior enlisted Rangers for combat by emphasizing discipline and training.
                                They have extensive schooling with a broad background of conventional to special operations experience.
                                NCOs ensure continued mission success and offer unique expertise for assignments in other Army units.
                            </div>
                            <CustomButton onClick={() => handleClick(4)}>Learn More</CustomButton>
                        </div>
                    </Reveal>
                </div>
            </div>
                {showDiv[4] &&
                    <div ref={dropDownFive} className={'assessmentDropDown-Container'}>
                        <div className={'enlistedDropDown-Content'}>
                            <div className={'enlistedDropDown-Text-Container'}>
                                <div style={{height:'auto'}}>
                                    <span className={'TextBold'} style={{fontWeight:'bold'}}>Junior Enlisted Rangers</span> are intelligent, disciplined, physically fit and well trained Soldiers.
                                    They possess enhanced combat skills and exceptional proficiency in infantry special operations tactics,
                                    techniques and procedures. Junior enlisted Rangers are the future of the Regiment and the Army.
                                </div>
                                <div style={{height:'auto', padding:'1rem 0 1rem 0'}}>
                                    Enlisted interested in an assignment to the Ranger Regiment should send an email to <span className={'TextColor'} style={{color:'#C72727'}}>75RECRUIT@SOCOM.MIL</span>and follow the below procedures:

                                    <ul>
                                        <li>
                                            Subject Line of the Email:
                                            <ul><li>
                                                Last, First, Rank - Initial/Return Application"
                                            </li></ul>
                                        </li>
                                        <li>
                                            Attachments:
                                            <ul><li>
                                                STP, all NCOERs, and AERs; and pre-screening questionnaire:
                                                <div><a className={'careerPath-hyperLink'} onClick={()=>{redirectToExternalSite('Pdf')}}>
                                                    RASP Pre-Screening Questionnaire</a>
                                                </div>
                                            </li></ul>
                                        </li>
                                        <li> State in the body of your email the following information:
                                            <ul>
                                                <li>
                                                    Current Duty Position and time in that position.
                                                </li>
                                                <li>
                                                    Month and year your Chain of Command supports your attendance to RASP 2.
                                                </li>
                                                <li>
                                                    Any unique qualifications you have that may distinguish you for your peers.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div style={{height:'auto'}}>
                                    <span className={'TextBold'} style={{fontWeight:'bold'}}>Staff Sergeant and above NCOs</span> interested in an assignment to the Ranger Regiment must contact the Ranger Recruiting Detachment at <span style={{color:'#C72727'}}>706-545-5124</span>.
                                    NCOs will be asked to submit a hiring packet to be considered for assignment by the RSM/CSMs.
                                    <br/><div className={'CareerPath-ParagraphOne'} style={{height:'5rem', display:'flex', alignItems:'center'}}>If you are approved for hire, you’ll attend RASP 2 TDY and Return.</div>
                                    The process time from submitting a packet and receiving word of your hire is an estimated 3-4 weeks.
                                </div>
                            </div>
                            <div style={{display:'flex', justifyContent:'center', alignItems:'flex-start'}}>
                                <img style={{maxWidth:'90%', width:'auto', height:'auto'}} src={enlistedDropDown} alt={'enlistedDropDown'}/>
                            </div>
                        </div>
                    </div>
                }
        </>
        )

}
export default CareerPath

