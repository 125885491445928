import React from "react";

const FaceBook = (fill: string, width: string, height: string) => {
    return(
        <svg width={width ? width : '27'} height={height ? height : "27"} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_11173_33547)">
                <path d="M13.5 0C6.04422 0 0 6.04422 0 13.5C0 19.831 4.35888 25.1435 10.2389 26.6026V17.6256H7.45524V13.5H10.2389V11.7223C10.2389 7.12746 12.3185 4.9977 16.8296 4.9977C17.685 4.9977 19.1608 5.16564 19.7645 5.33304V9.07254C19.4459 9.03906 18.8924 9.02232 18.205 9.02232C15.9916 9.02232 15.1362 9.86094 15.1362 12.0409V13.5H19.5458L18.7882 17.6256H15.1362V26.9012C21.8209 26.0939 27.0005 20.4023 27.0005 13.5C27 6.04422 20.9558 0 13.5 0Z" fill={fill}/>
            </g>
            <defs>
                <clipPath id="clip0_11173_33547">
                    <rect width='27' height="27" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}
export default FaceBook